import * as React from "react";
import { Box, Container, CssBaseline, Grid, Typography } from "@mui/material";
import DashboardContent from "./DashboardContent";

var sectionStyle = {
  marginTop: 33,
  marginBottom: 20,
  color: "transparent",
};

const Dashboard = (props: any) => {
  return (
    <>
      {/* <React.Fragment> */}
      {/* <CssBaseline /> */}
      <div style={sectionStyle}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid className="grid" container sx={{ width: "550px" }}>
            <Grid
              item
              md={4}
              sm={4}
              xs={4}
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#f9c920",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                Total Sales
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#ffeaf2",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                10,000
              </Typography>
            </Grid>
            <Grid
              item
              md={4}
              sm={4}
              xs={4}
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#f9c920",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                Volume
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#ffeaf2",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                10,000
              </Typography>
            </Grid>
            <Grid
              item
              md={4}
              sm={4}
              xs={4}
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#f9c920",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                Character Sold
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#ffeaf2",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                10,000
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <DashboardContent />
      </div>
      {/* </React.Fragment> */}
    </>
  );
};

export default Dashboard;
