import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
// import { Box } from "@mui/material/Box";
const Transition: any = React.forwardRef(function Transition(
  props: any,
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderSuccess(props: any) {
  let history = useHistory();

  const handleClickOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };
  const goToActivity = () => {
    history.push("/accounthistory");
  };
  const goToMarketPlace = () => {
    history.push("/blackmarket");
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-paper": {
            background: "#2f070c",
            // "linear-gradient(180deg, rgba(234,223,201,1) 0%, rgba(210,195,166,1) 100%)",
            border: "1px solid #ffffff",
            color: "#ffffff",
            borderRadius: "0px",
            width: "450px",
            height: "200px",
            p: 2,
            m: 2,
            // clipPath:
            //   "polygon(5% 0, 95% 0, 100% 5%, 100% 95%, 95% 100%, 5% 100%, 0 95%, 0 5%)",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: "Montserrat, medium, sans-serif",
            color: "#a26f74",
          }}
        >
          {"Congratulations!"}
        </DialogTitle>
        <DialogContent
        /* sx={{
            border: `1px solid #0b0c14`,
            background: "linear-gradient(180deg, rgba(234,223,201,1) 0%, rgba(210,195,166,1) 100%)",
          }} */
        >
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{
              color: "#ffffff",
              borderRadius: "0px",
              fontFamily: "Montserrat, medium, sans-serif",
            }}
          >
            Transaction Submitted Successfully
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            ml: 3,
          }}
        >
          <Box className="btn-border">
            <Box
              className="btn-inner"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                // variant="outlined"
                // color="success"
                sx={{
                  margin: "10px",
                  color: "#ffffff",
                  fontFamily: "Playfair Display SC, serif",
                }}
                onClick={goToMarketPlace}
              >
                Close
              </Button>
            </Box>
          </Box>
          <Box className="btn-activity">
            <Box
              className="btn"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                // variant="outlined"
                // color="#ffffff"
                sx={{
                  mr: 3.25,
                  color: "#ffffff",
                  fontSize: "12px",
                  fontFamily: "Playfair Display SC, serif",
                }}
                onClick={goToActivity}
              >
                Go To My Activity
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
