import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";


export default function ComingSoon() {
  return (
    <Container sx={{height:"80vh", justifyContent: "center", alignItems: "center", display: "flex"}} className="pageMaxSize">
      <Typography
        sx={{
          fontFamily: "MinionVariableConcept",
          fontSize: "68px",
          color: "#f4cd65",
          cursor: "default",
          mt:-15
        }}
      >
        Coming Soon
      </Typography>
    </Container>
  );
}
