import React from "react";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import SquareIcon from "@mui/icons-material/Square";
import { Typography } from "@mui/material";

export default function ChiefsRarity(props: any) {
  //const { Common, Rare, Epic, Legendary } = props.blockzSize;
  return (
    <Box sx={{ display: "flex" }}>
      <FormControl
        sx={{ my: 3, mt: 0, mb: 0 }}
        component="fieldset"
        variant="standard"
      >
        <FormGroup
          aria-label="position"
          row
          onChange={props.rarityHandleChange}
          // value={props.rarity}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <FormControlLabel
            key="Common"
            value="Common"
            control={
              <Checkbox
                checked={props.rarity.indexOf("Common") > -1}
                disableRipple
                icon={<SquareIcon fontSize="small" />}
                checkedIcon={<SquareIcon fontSize="small" />}
                // onChange={() => {
                //   props.setCommon(!props.common);
                // }}
                name="Common"
                sx={{
                  transform: "scale(0.75)",
                  color: "#ffffff",
                  borderRadius: "0px",
                  "&.Mui-checked": {
                    color: "#a26f74",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                Common
              </Typography>
            }
            labelPlacement="start"
            sx={{
              ml: -0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "space-between",
              width: "220px",
              "& .Mui-checked": {
                color: "#a26f74",
              },
              "& .MuiCheckbox-colorPrimary.Mui-checked + .MuiTypography-root": {
                color: "#a26f74",
              },
            }}
          />
          <FormControlLabel
            key="Rare"
            value="Rare"
            control={
              <Checkbox
                checked={props.rarity.indexOf("Rare") > -1}
                disableRipple
                icon={<SquareIcon fontSize="small" />}
                checkedIcon={<SquareIcon fontSize="small" />}
                // onChange={() => {
                //   props.setRare(!props.rare);
                // }}
                name="Rare"
                sx={{
                  transform: "scale(0.75)",
                  color: "#ffffff",
                  borderRadius: "0px",
                  "&.Mui-checked": {
                    color: "#a26f74",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                Rare
              </Typography>
            }
            labelPlacement="start"
            sx={{
              ml: -0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "space-between",
              width: "220px",
              "& .Mui-checked": {
                color: "#a26f74",
              },
              "& .MuiCheckbox-colorPrimary.Mui-checked + .MuiTypography-root": {
                color: "#a26f74",
              },
            }}
          />
          <FormControlLabel
            key="Epic"
            value="Epic"
            control={
              <Checkbox
                checked={props.rarity.indexOf("Epic") > -1}
                disableRipple
                icon={<SquareIcon fontSize="small" />}
                checkedIcon={<SquareIcon fontSize="small" />}
                // onChange={() => {
                //   props.setEpic(!props.epic);
                // }}
                name="Epic"
                sx={{
                  transform: "scale(0.75)",
                  color: "#ffffff",
                  "&.Mui-checked": {
                    color: "#a26f74",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                Epic
              </Typography>
            }
            labelPlacement="start"
            sx={{
              ml: -0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "space-between",
              width: "220px",
              "& .Mui-checked": {
                color: "#a26f74",
              },
              "& .MuiCheckbox-colorPrimary.Mui-checked + .MuiTypography-root": {
                color: "#a26f74",
              },
            }}
          />
          <FormControlLabel
            key="Legendary"
            value="Legendary"
            control={
              <Checkbox
                checked={props.rarity.indexOf("Legendary") > -1}
                disableRipple
                icon={<SquareIcon fontSize="small" />}
                checkedIcon={<SquareIcon fontSize="small" />}
                // onChange={() => {
                //   props.setLegendary(!props.legendary);
                // }}
                name="Legendary"
                sx={{
                  transform: "scale(0.75)",
                  color: "#ffffff",
                  borderRadius: "0px",
                  "&.Mui-checked": {
                    color: "#a26f74",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                Legendary
              </Typography>
            }
            labelPlacement="start"
            sx={{
              ml: -0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "space-between",
              width: "220px",
              "& .Mui-checked": {
                color: "#a26f74",
              },
              "& .MuiCheckbox-colorPrimary.Mui-checked + .MuiTypography-root": {
                color: "#a26f74",
              },
            }}
          />
        </FormGroup>
      </FormControl>
    </Box>
  );
}
