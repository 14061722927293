import React from "react";
import Skeleton from "@mui/material/Skeleton";

export default function TableSkeleton() {
  return (
    <>
        <Skeleton variant="text" height={80} width="100%" />
        <Skeleton variant="text" height={80} width="100%" />
        <Skeleton variant="text" height={80} width="100%" />
        <Skeleton variant="text" height={80} width="100%" />
        <Skeleton variant="text" height={80} width="100%" />
        <Skeleton variant="text" height={80} width="100%" />
        <Skeleton variant="text" height={80} width="100%" />
        <Skeleton variant="text" height={80} width="100%" />
        <Skeleton variant="text" height={80} width="100%" />
        <Skeleton variant="text" height={80} width="100%" />
        <Skeleton variant="text" height={80} width="100%" />
        <Skeleton variant="text" height={80} width="100%" />
    </>
  );
}
