import React from "react";
import { useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ComingSoon from "./ComingSoon";
import Avatar from "@mui/material/Avatar";
import DividerIcon from "src/assets/antara-exports/MainMenu/Title_Icon.png";
import Divider from "@mui/material/Divider";
import { useAuth } from "src/components/hooks/web3Auth";
import { useLocation, useHistory } from "react-router-dom";
import MarketplaceContent from "./marketplaceContent";
import Contracts from "../../contractData/contracts";
import leftDivider from "src/assets/antara-exports/MainMenu/left.png";
import rightDivider from "src/assets/antara-exports/MainMenu/right.png";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Profile() {
  const { login, account } = useAuth();
  const [value, setValue] = React.useState(1);
  let location: any = useLocation();
  let history = useHistory();
  const [type, setType] = React.useState(location.state?.type);
  const [curAccount, setCurAccount] = React.useState<any>();
  let currentAccountArr = location.pathname.split("/");
  let currentAccount = currentAccountArr[currentAccountArr.length - 1];
  useEffect(() => {
    console.log("current account is:" + currentAccount);
    setCurAccount(currentAccount);
  }, [location]);

  useEffect(() => {
    if (currentAccount !== account && !!account && !!currentAccount) {
      history.push(`/user/${account}`);
    }
  }, [account]);

  console.log("AAAAAa", curAccount);

  useEffect(() => {
    if (type === "Blockz") {
      setValue(1);
    } else if (type === "Dopez") {
      setValue(2);
    } else if (type === "Doz") {
      setValue(3);
    }
  }, [type]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log(event);
    setValue(newValue);
  };
  var sectionStyle = {
    marginTop: 0,
    color: "primary",
    cursor: "default",
  };
  var boxStyle = {
    width: "90%",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    border: `3px solid #0b0c14`,
    borderRadius: 3,
    backgroundColor: "#040508",
    color: "white",
    padding: 10,
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <div style={sectionStyle} className="pageMaxSize">
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
          }}
        >
          <Paper
            sx={{
              backgroundColor: "transparent",
              position: "relative",
              pt: 1,
              pb: 3,
              width: "100%",
              boxShadow: "none",
            }}
          >
            <TabPanel value={value} index={1}>
              <MarketplaceContent
                type="Character"
                path="profile"
                account={curAccount}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <MarketplaceContent
                type={Contracts.CAMEL}
                path="profile"
                account={curAccount}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <MarketplaceContent
                type={Contracts.KINGDOM}
                path="profile"
                account={curAccount}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <ComingSoon />
            </TabPanel>
          </Paper>
        </Box>
      </div>
    </React.Fragment>
  );
}
