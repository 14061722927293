import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import "@fontsource/montserrat";
import { Typography } from "@mui/material";

export default function EditListing(props: any) {
  console.log("ListingPrice", props.listingPrice);
  return (
    <>
      <Dialog
        open={props.openListForSale}
        onClose={props.handleCloseListForSale}
        sx={{
          "& .MuiDialog-paper": {
            background: "#2f070c",
            // "linear-gradient(180deg, rgba(234,223,201,1) 0%, rgba(210,195,166,1) 100%)",
            border: "1px solid #ffffff",
            color: "#ffffff",
            borderRadius: "0px",
            width: "450px",
            // clipPath:
            //   "polygon(5% 0, 95% 0, 100% 5%, 100% 95%, 95% 100%, 5% 100%, 0 95%, 0 5%)",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontFamily: "Montserrat, medium, sans-serif",
            color: "#a26f74",
          }}
        >
          Update Price
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText
            sx={{
              color: "#ffffff",
              borderRadius: "0px",
              fontFamily: "Montserrat, medium, sans-serif",
            }}
          >
            Please enter the price to list the NFT for Sale
          </DialogContentText> */}
          <Box
            sx={{
              "& .MuiInput-root": { borderRadius: "0px" },
            }}
          >
            <TextField
              autoFocus
              required
              size="small"
              margin="dense"
              id="name"
              label="Price"
              type="number"
              fullWidth
              error={props.listingPrice < 0}
              value={props.listingPrice}
              onChange={props.handlePriceChange}
              inputProps={{
                pattern: "[0-9]*",
                min: 0,
                style: {
                  fontFamily: "Montserrat, medium, sans-serif",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#ffffff",
                  fontFamily: "Montserrat, medium, sans-serif",
                },
              }}
              sx={{
                "& .MuiInputBase-input": {
                  color: "#ffffff !important",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#a26f74",
                  },
                  "&:hover fieldset": {
                    borderColor: "#ffffff",
                  },
                },
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#ffffff",
                  },

                color: "#ffffff",
                borderRadius: "0px",
                mt: 2,
              }}
            />
            {props.listingPrice > 9999 ? (
              <Typography
                color="#c82f2f"
                align="left"
                sx={{
                  fontSize: "11px",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                Price should be lesser than 9999
              </Typography>
            ) : null}
            {props.listingPrice < 1 ? (
              <Typography
                color="#c82f2f"
                align="left"
                sx={{
                  fontSize: "11px",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                Price should be greater than 0
              </Typography>
            ) : null}
          </Box>
        </DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DialogActions sx={{ mb: 2 }}>
            {/* <Box className="btn-border">
              <Box
                className="btn-inner"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={props.handleCloseListForSale}
                  sx={{
                    margin: "10px",
                    color: "#ffffff",
                    fontFamily: "Playfair Display SC, serif",
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box> */}
            <Box className="btn-border">
              <Box
                className="btn-inner"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => {
                    props.action();
                    props.handleCloseListForSale();
                  }}
                  sx={{
                    "&.Mui-disabled": { color: "#200105" },
                    margin: "10px",
                    color: "#ffffff",
                    fontFamily: "Playfair Display SC, serif",
                  }}
                  disabled={
                    props.listingPrice < 1 ||
                    props.listingPrice > 9999 ||
                    !props.listingPrice
                  }
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
