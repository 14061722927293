import * as React from "react";
import Box, { BoxProps } from "@mui/material/Box";

export default function DetailCard(props: BoxProps) {
  const { sx, ...other } = props;
  return (
    <Box
      width={props.width}
      height={props.height}
      //   onClick={props.onClick}
      sx={{
        p: 1,
        m: 1,
        background: "transparent",
        // bgcolor: (theme) =>
        //   theme.palette.mode === "dark" ? "#101010" : "grey.100",
        // color: (theme) =>
        //   theme.palette.mode === "dark" ? "grey.300" : "grey.800",
        color: "white",
        border: "0.5px solid #a26f74",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "#a26f74" : "#a26f74",
        borderRadius: 0,
        // fontSize: "0.875rem",
        // fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}
