import * as React from "react";
import { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth, useContract, useEagerConnect } from "../hooks/web3Auth";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Web3 from "web3";
import axios from "axios";
import BuyAndSell from "./BuyAndSell";
import OrderSuccess from "../dialogues/order-success";
import EditListing from "../dialogues/editListing";
import DividerIcon from "src/assets/antara-exports/MainMenu/Title_Icon.png";
import Divider from "@mui/material/Divider";
import Backdrop from "@mui/material/Backdrop";
import NftImg from "./NftImg";
import CircularProgress from "@mui/material/CircularProgress";
import DynamicInfo from "./DynamicInfo";
import { useIsPreLaunchEnabled } from "../hooks/preLaunch";
import Alert from "@mui/material/Alert";
import Drugz from "../../contractData/DrugzAbi";
import BigNumber from "bignumber.js";
import DetailCard from "../card/DetailCard";
//Rarity
import Rare from "../../assets/antara-exports/MainMenu/Raree.png";
import Common from "../../assets/antara-exports/MainMenu/Common.png";
import Epic from "../../assets/antara-exports/MainMenu/Epic.png";
import Legendary from "../../assets/antara-exports/MainMenu/Legendary.png";
//Class
import Soldier from "../../assets/antara-exports/MainMenu/Soldier.png";
import Ranged from "../../assets/antara-exports/MainMenu/Ranged.png";
import Support from "../../assets/antara-exports/MainMenu/Support.png";
import Brute from "../../assets/antara-exports/MainMenu/Brute.png";
import Mounted from "../../assets/antara-exports/MainMenu/Mounted.png";
//Faction
import Romulus from "../../assets/antara-exports/MainMenu/Romulus.png";
import Remus from "../../assets/antara-exports/MainMenu/Remus.png";
import Greek from "../../assets/antara-exports/MainMenu/Greek.png";
import AfricanTribe from "../../assets/antara-exports/MainMenu/AfricanTribe.png";
import Attack from "../../assets/antara-exports/MainMenu/AttackRule.png";
import UltimateRule from "../../assets/antara-exports/MainMenu/UltimateRule.png";
import SalesHistory from "./SalesHistory";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@fontsource/montserrat";
import Contracts from "../../contractData/contracts";
import GameManager from "../../contractData/gameManagerabi";

const factionIcons = [
  {
    name: "Romulus",
    icon: Romulus,
  },
  { name: "Remus", icon: Remus },
  {
    name: "GreekEmpire",
    icon: Greek,
  },
  { name: "AfricanTribe", icon: AfricanTribe },
  { name: "TBD", icon: Common },
];

const classIcons = [
  {
    name: "Soldier",
    icon: Soldier,
  },
  { name: "Ranged", icon: Ranged },
  {
    name: "Brute",
    icon: Brute,
  },
  { name: "Support", icon: Support },
  { name: "Mounted", icon: Mounted },
];

const rarityIcons = [
  {
    name: "Common",
    icon: Common,
  },
  { name: "Rare", icon: Rare },
  {
    name: "Epic",
    icon: Epic,
  },
  { name: "Legendary", icon: Legendary },
];

export default function BlockzDetail(props: any) {
  const preLaunchEnabled: boolean = useIsPreLaunchEnabled();
  useEagerConnect();
  let location: any = useLocation();
  const [cards, setCards] = useState(location.state?.card);
  const [type, setType] = useState(location.state?.type);
  const [buySuccess, setBuySuccess] = React.useState(false);
  const [backdrop, setBackdrop] = React.useState(false);
  const { account, switchNetwork } = useAuth();

  const { contract: drguzContract, methods: drugzMethods } = useContract(
    Drugz.abi,
    Drugz.address
  );

  const { contract: gmContract, methods: gmMethods } = useContract(
    GameManager.abi,
    GameManager.address
  );
  const web3 = new Web3(Web3.givenProvider);
  let history = useHistory();
  var sectionStyle = {
    marginTop: 20,
  };

  const [SnackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  useEffect(() => {
    if (cards?.price) {
      setListingPrice(cards?.price);
      // console.log("---Listing----", listingPrice);
    }
  }, [cards]);

  // const [SnackbarOpen] = React.useState(false);

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  // console.log("cccccccccccccccc", location.state);
  const [open, setOpen] = useState(false);

  const [openListForSale, setOpenListForSale] = useState(false);
  const [listingPrice, setListingPrice] = useState();
  const [openEditListing, setOpenEditListing] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const [bnbPrice, setBnbPrice] = useState(1);

  const getFactionIcon = (item: any) => {
    const icon = factionIcons.find((fact: any) => fact.name === item)?.icon;
    return icon;
  };

  const getClassIcon = (item: any) => {
    const icon = classIcons.find((fact: any) => fact.name === item)?.icon;
    return icon;
  };

  const getRarityIcon = (item: any) => {
    const icon = rarityIcons.find((fact: any) => fact.name === item)?.icon;
    return icon;
  };

  const handlePriceChange = (event: any) => {
    setListingPrice(event.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseListForSale = () => {
    setOpenListForSale(false);
    setBackdrop(false);
  };

  const handleCloseEditListing = () => {
    setOpenEditListing(false);
    setBackdrop(false);
  };

  function handleClick() {
    history.push({
      pathname: "/",
      state: { type: type },
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log("Token id: ", cards);

    console.log("current route is: ", location.pathname);
    let currentType;
    let currentCards: any = {};
    let elements = location.pathname.split("/");
    console.table("ELEMENTS", elements);
    if (elements[1] === "nft") {
      console.log("TYPEEEEEEEEEEE", elements[1]);
      currentType = "Character";
    }
    setType(currentType);
    axios
      .post("/api/listForSale/get-by-id", {
        type: currentType,
        tokenId: elements[2],
      })
      .then(function (response: any) {
        console.log("RESPONSEEEEEEEEEEE", response.data.nfts.data);
        if (response.data.nfts.data.length) {
          //response.data.nfts.data[0]["nft"] = response.data.nfts.data[0]?.Nft;
          setCards(response.data.nfts.data[0]);
          currentCards = response.data.nfts.data[0];
          console.log("CARDSSSSSSSSSSSSSSSS", currentCards);
          props["type"] = "Character";
          setType("Character");
        }

        let url = "/api/transactions/nft";
        let payload;
        if (cards === undefined || cards === null) {
          payload = {
            tokenId: currentCards?.nft?._id,
          };
        } else {
          payload = {
            tokenId: cards?.nft._id,
          };
        }

        axios
          .post(url, payload)
          .then(function (response: any) {
            console.log(response);
            setTransactionHistory(response.data.transactions);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get("https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDC")
      .then(function (response: any) {
        console.log("BNB", response);
        setBnbPrice(response.data.price);
      })
      .catch(function (error) {
        console.log(error);
      });

    // let url = "/api/transactions/nft";
    // let payload = {
    //   tokenId: cards.nft._id,
    // };

    // axios
    //   .post(url, payload)
    //   .then(function (response: any) {
    //     console.log("Transction: ", response);
    //     setTransactionHistory(response.data.transactions);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }, []);
  const buyNft = async () => {
    if (account) {
      // console.log("in buy nft price is:" + bnbPrice);
      setBackdrop(true);
      axios
        .post("/api/listForSale/get-by-id", {
          type: type,
          tokenId: cards.Nft.tokenId,
        })
        .then(function (response: any) {
          if (
            response.data.nfts.data &&
            response.data.nfts.data[0].price === cards.price
          ) {
            let url = "/api/nfts/buy-nft";
            let payload = {
              tokenId: cards.nft._id,
              wallet: account,
            };
            axios
              .post(url, payload)
              .then(async function (response: any) {
                console.log(response);
                let tuple = [
                  response.data.transferNftData.buyer,
                  response.data.transferNftData.seller,
                  web3.utils.toWei(cards.price.toFixed(10).toString()),
                  response.data.transferNftData.tokenId,
                  response.data.transferNftData.token,
                  response.data.transferNftData.platformRatio.toString(),
                  response.data.transferNftData.collection,
                ];
                drugzMethods
                  .approve(
                    GameManager.address,
                    web3.utils.toWei(cards.price.toFixed(10).toString())
                  )
                  .send({
                    from: account,
                    gasPrice: parseInt(`${new BigNumber(10).pow(10)}`),
                  })
                  .on("transactionHash", (tx: string) => {
                    console.log("hash", tx);
                  })
                  .on("receipt", (rc: any) => {
                    console.log("receipt", rc);
                    console.log(response.data.signature);
                    let tuple = [
                      response.data.transferNftData.buyer,
                      response.data.transferNftData.seller,
                      response.data.transferNftData.amount,
                      response.data.transferNftData.tokenId.toString(),
                      response.data.transferNftData.token,
                      response.data.transferNftData.platformRatio.toString(),
                      response.data.transferNftData.collection,
                    ];
                    gmMethods
                      .transferNft(tuple, response.data.signature)
                      .send({
                        from: account,
                        gasPrice: parseInt(`${new BigNumber(10).pow(10)}`),
                      })
                      .on("transactionHash", (tx: string) => {
                        console.log("hash", tx);
                      })
                      .on("receipt", (rc: any) => {
                        console.log("receipt", rc);
                        cards.nft.ownerAddress = account;
                        setBackdrop(false);
                        setBuySuccess(true);
                      })
                      .on("error", (error: any, receipt: any) => {
                        console.log("error", error, receipt);
                        setBackdrop(false);
                      });
                  })
                  .on("error", (error: any, receipt: any) => {
                    console.log("error", error, receipt);
                    setBackdrop(false);
                  });
              })
              .catch(function (error) {
                console.log(error);
                setBackdrop(false);
              });
          } else {
            setSnackbarMessage("Price Updated");
            setSnackbarOpen(true);
            setBackdrop(false);
            cards.price = response.data.nfts.data[0].price;
            cards.nft.image = response.data.nfts.data[0].nft.image;
            cards.Nft.image = response.data.nfts.data[0].Nft.image;
          }
        })
        .catch(function (error) {
          console.log(error);
          setSnackbarMessage("NFT No Longer Available for Sale");
          setSnackbarOpen(true);
          setBackdrop(false);
        });
    } else {
      return (
        <Snackbar
          open={SnackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message="Please connect your Wallet first"
        />
      );
    }
  };
  const listForSale = () => {
    let acc = account ? account : "";
    setLoading(true);
    axios
      .post("/api/signedData", {
        tokenId: cards.nft._id,
        action: "create",
      })
      .then(function (response: any) {
        web3.eth.personal
          .sign(web3.utils.toHex(response.data.signedData.data), acc, "")
          .then(function (receipt: any) {
            console.log("RECEIPT: ", receipt);
            let url = "/api/listForSale/create-listing";
            let payload = {
              tokenId: cards.nft._id,
              wallet: account,
              price: listingPrice,
              signature: receipt,
              signingDataId: response.data.signedData._id,
            };
            axios
              .post(url, payload)
              .then(function (response: any) {
                toast(`${cards?.nft?.name} has been listed successfully`, {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setTimeout(() => {
                  history.push("/blackmarket");
                }, 2000);
                handleCloseListForSale();
                setBackdrop(false);
              })
              .catch(function (error) {
                console.log(error);
                setBackdrop(false);
                setLoading(false);
              });
          })
          .finally(() => {
            setBackdrop(false);
            setLoading(false);
          });
      })
      .catch(function (error) {
        console.log(error);
        setBackdrop(false);
        setLoading(false);
      });
  };

  const editListing = () => {
    let acc = account ? account : "";
    setLoading(true);
    axios
      .post("/api/signedData", {
        tokenId: cards.nft._id,
        action: "edit",
      })
      .then(function (response: any) {
        web3.eth.personal
          .sign(web3.utils.toHex(response.data.signedData.data), acc, "")
          .then(function (receipt: any) {
            let url = "/api/listForSale/update-listing";
            let payload = {
              tokenId: cards.nft._id,
              wallet: account,
              price: listingPrice,
              signature: receipt,
              signingDataId: response.data.signedData._id,
            };
            axios
              .post(url, payload)
              .then(function (response: any) {
                console.log("Listing", response);
                toast(`${cards?.nft?.name} has been updated successfully`, {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setTimeout(() => {
                  history.push("/blackmarket");
                }, 2000);
                handleCloseEditListing();
                setBackdrop(false);
              })
              .catch(function (error) {
                console.log(error);
                setBackdrop(false);
                setLoading(false);
              });
          })
          .finally(() => {
            setBackdrop(false);
            setLoading(false);
          });
      })
      .catch(function (error) {
        console.log(error);
        setBackdrop(false);
        setLoading(false);
      });
  };
  const cancelListing = () => {
    let acc = account ? account : "";
    setLoading(true);
    axios
      .post("/api/signedData", {
        tokenId: cards.nft._id,
        action: "delete",
      })
      .then(function (response: any) {
        web3.eth.personal
          .sign(web3.utils.toHex(response.data.signedData.data), acc, "")
          .then(function (receipt: any) {
            let url = "/api/listForSale/delete-listing";
            let payload = {
              tokenId: cards.nft._id,
              wallet: account,
              signature: receipt,
              signingDataId: response.data.signedData._id,
            };
            axios
              .post(url, payload)
              .then(function (response: any) {
                console.log(response);
                toast(`${cards?.nft?.name} has been unlisted successfully`, {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setTimeout(() => {
                  history.push("/blackmarket");
                }, 2000);
              })
              .catch(function (error) {
                setLoading(false);
                console.log(error);
                setLoading(false);
              });
          })
          .finally(() => {
            setBackdrop(false);
            setLoading(false);
            setLoading(false);
          });
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
        setBackdrop(false);
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={1900}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <CssBaseline />
      <div style={sectionStyle}>
        <Container maxWidth="xl" className="pageMaxSize">
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "87%",
              // display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // ml: "5%",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <Grid item md={6} sm={12}>
                <Paper
                  sx={{
                    backgroundColor: "transparent",
                    position: "relative",
                    pt: 2,
                    pb: 3,
                    boxShadow: "none",
                  }}
                >
                  <Grid
                    container
                    spacing={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        ml: 16.5,
                        // display: "flex",
                        width: "450px",
                        height: "400px",
                        // justifyContent: "center",
                        // alignItems: "center",
                        border: "1px solid #cf9b36",
                        mt: 3,
                        // p: 2,
                        // mr: 3,
                      }}
                    >
                      <NftImg
                        img={cards?.nft?.image}
                        type={cards?.nft?.collectionId?.type}
                      />
                    </Box>
                    <Grid
                      container
                      // display="flex"
                      sx={{
                        border: "0.5px solid #a26f74",
                        ml: 16.5,
                        width: "450px",
                        my: 1,
                        p: 0.5,
                      }}
                    >
                      <Grid
                        item
                        md={4}
                        sm={12}
                        xs={12}
                        sx={{
                          display: "flex",
                          // flexDirection: "row",
                        }}
                      >
                        <Box
                          sx={{
                            // p: 1,
                            m: 1,
                            // height: "50px",
                            width: "120px",
                            // border: "0.5px solid #a26f74",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            item
                            md={6}
                            sm={12}
                            xs={12}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <img
                              src={getFactionIcon(
                                cards?.nft?.metadata?.faction
                              )}
                              width="33px"
                              style={{ marginRight: 8, marginTop: 5 }}
                            ></img>
                            <Typography
                              // align="center"
                              sx={{
                                fontSize: "14px",
                                color: "#a26f74",
                                fontFamily: "Montserrat, medium, sans-serif",
                              }}
                            >
                              Faction
                            </Typography>
                          </Grid>
                          <Grid item md={12}>
                            <Typography
                              align="left"
                              sx={{
                                mt: -2.25,
                                ml: 5.25,
                                fontSize: "15px",
                                color: "#ffffff",
                                fontFamily: "Montserrat, medium, sans-serif",
                              }}
                            >
                              {cards?.nft?.metadata?.faction}
                            </Typography>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        md={4}
                        sm={12}
                        xs={12}
                        sx={{
                          display: "flex",
                          // flexDirection: "row",
                        }}
                      >
                        <Box
                          sx={{
                            // p: 1,
                            m: 1,
                            // height: "50px",
                            width: "120px",
                            // border: "0.5px solid #a26f74",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            item
                            md={4}
                            sm={12}
                            xs={12}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <img
                              src={getClassIcon(cards?.nft?.metadata?.class)}
                              width="33px"
                              style={{ marginRight: 8, marginTop: 5 }}
                            ></img>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#a26f74",
                                fontFamily: "Montserrat, medium, sans-serif",
                              }}
                            >
                              Class
                            </Typography>
                          </Grid>
                          <Typography
                            align="left"
                            sx={{
                              mt: -2.75,
                              ml: 5.25,
                              fontSize: "15px",
                              color: "#ffffff",
                              fontFamily: "Montserrat, medium, sans-serif",
                            }}
                          >
                            {cards?.nft?.metadata?.class}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        md={4}
                        sm={12}
                        xs={12}
                        sx={{
                          display: "flex",
                          // flexDirection: "row",
                        }}
                      >
                        <Box
                          sx={{
                            // p: 1,
                            m: 1,
                            // height: "50px",
                            // width: "120px",
                            // border: "0.5px solid #a26f74",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            item
                            md={4}
                            sm={12}
                            xs={12}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <img
                              src={getRarityIcon(cards?.nft?.metadata?.rarity)}
                              width="33px"
                              style={{ marginRight: 8, marginTop: 5 }}
                            ></img>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#a26f74",
                                fontFamily: "Montserrat, medium, sans-serif",
                              }}
                            >
                              Rarity
                            </Typography>
                          </Grid>
                          <Typography
                            align="left"
                            sx={{
                              mt: -2.75,
                              ml: 5.25,
                              fontSize: "15px",
                              color: "#ffffff",
                              fontFamily: "Montserrat, medium, sans-serif",
                            }}
                          >
                            {cards?.nft?.metadata?.rarity}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{
                          display: "flex",
                          // flexDirection: "row",
                        }}
                      >
                        <Box
                          sx={{
                            // p: 1,
                            m: 1,
                            // height: "50px",
                            // width: "120px",
                            // border: "0.5px solid #a26f74",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            item
                            md={6}
                            sm={12}
                            xs={12}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <img
                              src={Attack}
                              width="33px"
                              style={{ marginRight: 10, marginTop: 5.5 }}
                            ></img>
                            <Typography
                              align="center"
                              sx={{
                                fontSize: "14px",
                                color: "#a26f74",
                                fontFamily: "Montserrat, medium, sans-serif",
                                // ml: 4,
                              }}
                            >
                              Ability
                            </Typography>
                          </Grid>
                          <Typography
                            align="left"
                            sx={{
                              mt: -2.5,
                              ml: 5.5,
                              fontSize: "15px",
                              color: "#ffffff",
                              fontFamily: "Montserrat, medium, sans-serif",
                            }}
                          >
                            {cards?.nft?.metadata?.attackRule}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{
                          display: "flex",
                          // flexDirection: "row",
                        }}
                      >
                        <Box
                          sx={{
                            // p: 1,
                            m: 1,
                            // height: "50px",
                            // width: "125px",
                            // border: "0.5px solid #a26f74",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            item
                            md={6}
                            sm={12}
                            xs={12}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <img
                              src={UltimateRule}
                              width="33px"
                              style={{ marginRight: 10, marginTop: 5.5 }}
                            ></img>
                            <Typography
                              sx={{
                                fontSize: "15px",
                                color: "#a26f74",
                                fontFamily: "Montserrat, medium, sans-serif",
                                // ml: 3,
                              }}
                            >
                              Ultimate
                            </Typography>
                          </Grid>
                          <Typography
                            align="left"
                            sx={{
                              mt: -2.5,
                              ml: 5.5,
                              fontSize: "15px",
                              color: "#ffffff",
                              fontFamily: "Montserrat, medium, sans-serif",
                            }}
                          >
                            {cards?.nft?.metadata?.ultimateRule}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    <Box display="flex" sx={{ ml: 16.5 }}>
                      <Grid item xs={12} sm={12} md={12}>
                        {preLaunchEnabled ? (
                          <></>
                        ) : (
                          <BuyAndSell
                            cards={cards}
                            ownerAddress={cards?.nft?.ownerAddress}
                            account={account}
                            buyNFT={buyNft}
                            handlePriceChange={handlePriceChange}
                            action={listForSale}
                            listingPrice={listingPrice}
                            // listForSale={setOpenListForSale}
                            editListing={setOpenEditListing}
                            cancelListing={cancelListing}
                            setBackdrop={setBackdrop}
                            loading={loading}
                          />
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item md={6} sm={12}>
                <Paper
                  sx={{
                    backgroundColor: "transparent",
                    position: "relative",
                    pl: 0,
                    pb: 3,
                    boxShadow: "none",
                  }}
                >
                  <DynamicInfo cards={cards} type={type} />
                </Paper>
              </Grid>
            </Grid>
          </Box>

          <Dialog
            open={open}
            onClose={handleClose}
            sx={{
              "& .MuiDialog-paper": {
                backgroundColor: "#2f070c",
                border: "1px solid #ffffff",
              },
            }}
          >
            <DialogTitle>List for sale</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter the price to list the NFT for Sale
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Price"
                type="number"
                fullWidth
                // variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                variant="outlined"
                color="error"
                sx={{ margin: "10px" }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleClose}
                variant="outlined"
                color="success"
                sx={{ margin: "10px" }}
              >
                List
              </Button>
            </DialogActions>
          </Dialog>
          {/* <EditListing
            listingPrice={listingPrice}
            handlePriceChange={handlePriceChange}
            openListForSale={openListForSale}
            handleCloseListForSale={handleCloseListForSale}
            action={listForSale}
          /> */}
          <EditListing
            listingPrice={listingPrice}
            handlePriceChange={handlePriceChange}
            openListForSale={openEditListing}
            handleCloseListForSale={handleCloseEditListing}
            action={editListing}
            loading={loading}
          />
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Container>
      </div>
      <OrderSuccess open={buySuccess} setOpen={setBuySuccess} />
      <Snackbar
        open={SnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </React.Fragment>
  );
}
