import React from "react";
import Grid from "@mui/material/Grid";
import DividerIcon from "src/assets/antara-exports/MainMenu/Title_Icon.png";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
// import Logo from "../../assets/antara-exports/LogoOnMintingPage.png";
import Building from "../../assets/antara-exports/Building.png";
import King from "../../assets/antara-exports/King.png";
import DigitalLand from "../../assets/antara-exports/DigitalLand.png";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import IconButton from "@mui/material/IconButton";

export default function Minting() {
  const [noOfChiefs, setNoOfChiefs] = React.useState<number>(1);
  const [landSize, setLandSize] = React.useState<number>(1);
  const MintChiefs = () => {};
  const MintLands = () => {};
  const increaseLands = () => {
    if (landSize + 1 <= 5) {
      setLandSize(landSize + 1);
    }
  };
  const decreaseLands = () => {
    if (landSize - 1 >= 1) {
      setLandSize(landSize - 1);
    }
  };
  const increaseChiefs = () => {
    if (noOfChiefs + 1 <= 5) {
      setNoOfChiefs(noOfChiefs + 1);
    }
  };
  const decreaseChiefs = () => {
    if (noOfChiefs - 1 >= 1) {
      setNoOfChiefs(noOfChiefs - 1);
    }
  };

  return (
    <>
      <Container
        sx={{
          minHeight: "200vh",
          margin: "0 !important",
          padding: "0 !important",
          maxWidth: "100% !important",
        }}
      >
        <Grid
          container
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Grid item md={6} sm={6} xs={6} className="minting-bg">
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "34px",
                color: "#f4cd65",
                mt: 1,
                cursor: "default",
              }}
            >
              {/* <img width={"50%"} src={Logo} alt="DopeWarz Logo Header" /> */}
            </Typography>
            <Divider
              sx={{
                width: "60%",
                mx: "20%",
                "&::before, &::after": {
                  borderColor: "#f4cd65",
                  borderWidth: "2px",
                  padding: 0,
                },
                "&.MuiDivider-wrapper, &.MuiDivider-root": {
                  p: 0,
                },
              }}
            >
              <Avatar
                variant="square"
                alt="test avatar"
                src={DividerIcon}
                sx={{ height: "10px", width: "10px", p: 0 }}
              />
            </Divider>
            <Typography
              sx={{
                mx: "10%",
                fontFamily: "MinionVariableConcept",
                fontSize: "18px",
                color: "#ffffff",
                mt: 1,
                cursor: "default",
                textAlign: "justify",
              }}
            >
              A blockchain-based, base-building, management game with combat
              mechanics. Players will get a free tribe to upgrade and build a
              base around. As they grow, they’ll manage resources and build
              units to fight other players. Climb the competitive ladder, win
              rewards and reign supreme while using the Arabian camel NFTs in
              conjunction with your purchased chief.
            </Typography>
          </Grid>
          <Grid item md={6} sm={6} xs={6}>
            <img width={"100%"} src={Building} alt="DopeWarz Logo Header" />
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            justifyContent: "center",
            alignItems: "center",
            px: "10%",
            mt: 10,
          }}
        >
          <Grid item md={4} sm={12} xs={12}>
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "26px",
                color: "#f4cd65",
                mt: 1,
                cursor: "default",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              Round 1
            </Typography>
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "20px",
                color: "#ffffff",
                cursor: "default",
                textAlign: "center",
              }}
            >
              March 24th
            </Typography>
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "20px",
                color: "#ffffff",
                mt: 2,
                cursor: "default",
                textAlign: "center",
              }}
            >
              2pm UTC for 24 Hours
            </Typography>
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "20px",
                color: "#ffffff",
                cursor: "default",
                textAlign: "center",
              }}
            >
              (or 1000 sold out)
            </Typography>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "26px",
                color: "#f4cd65",
                mt: 1,
                cursor: "default",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              Round 2
            </Typography>
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "20px",
                color: "#ffffff",
                cursor: "default",
                textAlign: "center",
              }}
            >
              April 4th
            </Typography>
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "20px",
                color: "#ffffff",
                mt: 2,
                cursor: "default",
                textAlign: "center",
              }}
            >
              2pm UTC for 24 Hours
            </Typography>
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "20px",
                color: "#ffffff",
                cursor: "default",
                textAlign: "center",
              }}
            >
              (or 1000 sold out)
            </Typography>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "26px",
                color: "#f4cd65",
                mt: 1,
                cursor: "default",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              Round 3
            </Typography>
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "20px",
                color: "#ffffff",
                cursor: "default",
                textAlign: "center",
              }}
            >
              May 7th
            </Typography>
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "20px",
                color: "#ffffff",
                mt: 2,
                cursor: "default",
                textAlign: "center",
              }}
            >
              2pm UTC for 24 Hours
            </Typography>
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "20px",
                color: "#ffffff",
                cursor: "default",
                textAlign: "center",
              }}
            >
              (or 1000 sold out)
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            justifyContent: "center",
            alignItems: "center",
            px: "5%",
            mt: 8,
          }}
        >
          <Grid item md={3} sm={12} xs={12} sx={{ px: 2, height: "618px" }}>
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "26px",
                color: "#f4cd65",
                mt: 1,
                cursor: "default",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              Shaddad (Swordsman)
            </Typography>
            <Divider
              sx={{
                "&::before, &::after": {
                  borderColor: "#f4cd65",
                  borderWidth: "2px",
                  padding: 0,
                },
                "&.MuiDivider-wrapper, &.MuiDivider-root": {
                  p: 0,
                },
              }}
            >
              <Avatar
                variant="square"
                alt="test avatar"
                src={DividerIcon}
                sx={{ height: "10px", width: "10px", p: 0 }}
              />
            </Divider>
            <img
              width={"70%"}
              src={King}
              alt="DopeWarz Logo Header"
              style={{
                marginTop: "15px",
                marginBottom: "15px",
              }}
            />
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "18px",
                color: "#ffffff",
                cursor: "default",
                textAlign: "center",
              }}
            >
              Shaddad is a highly charismatic and exuberant chieftain. His
              massive health pool enables him to protect his tribe. While his
              extensive battle experience and connections to his tribespeople
              helps the Bedouins on the battlefield.
            </Typography>
          </Grid>
          <Grid item md={3} sm={12} xs={12} sx={{ px: 2, height: "618px" }}>
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "26px",
                color: "#f4cd65",
                mt: 1,
                cursor: "default",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              Abla (Archer)
            </Typography>
            <Divider
              sx={{
                "&::before, &::after": {
                  borderColor: "#f4cd65",
                  borderWidth: "2px",
                  padding: 0,
                },
                "&.MuiDivider-wrapper, &.MuiDivider-root": {
                  p: 0,
                },
              }}
            >
              <Avatar
                variant="square"
                alt="test avatar"
                src={DividerIcon}
                sx={{ height: "10px", width: "10px", p: 0 }}
              />
            </Divider>
            <img
              width={"70%"}
              src={King}
              alt="DopeWarz Logo Header"
              style={{
                marginTop: "15px",
                marginBottom: "15px",
              }}
            />
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "18px",
                color: "#ffffff",
                cursor: "default",
                textAlign: "center",
              }}
            >
              The daughter of the king, a woman of elegance and extremely high
              rank. Her high agility and range allows her to get rid of the
              troubling frontier buildings so her comrades in arms can do their
              tasks seamlessly. Having her on the battlefield will empower her
              fellow archers to deal more damage.
            </Typography>
          </Grid>
          <Grid item md={3} sm={12} xs={12} sx={{ px: 2, height: "618px" }}>
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "26px",
                color: "#f4cd65",
                mt: 1,
                cursor: "default",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              Zabeebah (Fire Thrower)
            </Typography>
            <Divider
              sx={{
                "&::before, &::after": {
                  borderColor: "#f4cd65",
                  borderWidth: "2px",
                  padding: 0,
                },
                "&.MuiDivider-wrapper, &.MuiDivider-root": {
                  p: 0,
                },
              }}
            >
              <Avatar
                variant="square"
                alt="test avatar"
                src={DividerIcon}
                sx={{ height: "10px", width: "10px", p: 0 }}
              />
            </Divider>
            <img
              width={"70%"}
              src={King}
              alt="DopeWarz Logo Header"
              style={{
                marginTop: "15px",
                marginBottom: "15px",
              }}
            />
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "18px",
                color: "#ffffff",
                cursor: "default",
                textAlign: "center",
              }}
            >
              Elegance, strength and intellect, Zabeebah will burn anything that
              tries to harm her people with her HP boost to her nearby allies
              along with her attacks that can deal damage to multiple buildings.
            </Typography>
          </Grid>
          <Grid item md={3} sm={12} xs={12} sx={{ px: 2, height: "618px" }}>
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "26px",
                color: "#f4cd65",
                mt: 1,
                cursor: "default",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              Antara (Javelin Thrower)
            </Typography>
            <Divider
              sx={{
                "&::before, &::after": {
                  borderColor: "#f4cd65",
                  borderWidth: "2px",
                  padding: 0,
                },
                "&.MuiDivider-wrapper, &.MuiDivider-root": {
                  p: 0,
                },
              }}
            >
              <Avatar
                variant="square"
                alt="test avatar"
                src={DividerIcon}
                sx={{ height: "10px", width: "10px", p: 0 }}
              />
            </Divider>
            <img
              width={"70%"}
              src={King}
              alt="DopeWarz Logo Header"
              style={{
                marginTop: "15px",
                marginBottom: "15px",
              }}
            />
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "18px",
                color: "#ffffff",
                cursor: "default",
                textAlign: "center",
              }}
            >
              Born into slavery, he fights not just for freedom and respect but
              immortality. While his sturdy build allows him to over any walls,
              his high power javelins can deal damage to multiple buildings with
              their explosive attack power.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            justifyContent: "center",
            alignItems: "center",
            px: "5%",
            mt: 8,
            height: "618px",
          }}
        >
          <Grid item md={12} sm={12} xs={12} sx={{ px: 2, minHeight: "618px" }}>
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "26px",
                color: "#f4cd65",
                mt: 1,
                cursor: "default",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              Digital Land
            </Typography>
            <Divider
              sx={{
                width: "30%",
                mx: "35%",
                "&::before, &::after": {
                  borderColor: "#f4cd65",
                  borderWidth: "2px",
                  padding: 0,
                },
                "&.MuiDivider-wrapper, &.MuiDivider-root": {
                  p: 0,
                },
              }}
            >
              <Avatar
                variant="square"
                alt="test avatar"
                src={DividerIcon}
                sx={{ height: "10px", width: "10px", p: 0 }}
              />
            </Divider>
            <img
              width={"18%"}
              src={DigitalLand}
              alt="DopeWarz Logo Header"
              style={{
                marginTop: "15px",
                marginBottom: "15px",
              }}
            />
            <Typography
              sx={{
                mx: "20%",
                fontFamily: "MinionVariableConcept",
                fontSize: "17px",
                color: "#ffffff",
                cursor: "default",
                textAlign: "center",
              }}
            >
              Joining a kingdom provides many benefits to the player. Depending
              on the land they’re on they can get high resource production,
              substantial decrease in upgrade and build costs or boosted tower
              damage and health. Furthermore you’ll be able to partake in
              Kingdom wars which can be a major source of earning. Along with
              this investors who buy Kingdoms will get profits on any
              transactions performed by the players along with APR.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            justifyContent: "center",
            alignItems: "center",
            px: "10%",
            mt: 5,
            mb: 5,
          }}
        >
          <Grid item md={6} sm={12} xs={12} sx={{ px: 2, minHeight: "360px" }}>
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "26px",
                color: "#f4cd65",
                mt: 1,
                cursor: "default",
                textAlign: "left",
                fontWeight: "600",
              }}
            >
              Chiefs Utility
            </Typography>
            <Typography
              sx={{
                mt: 1,
                minHeight: "80px",
                fontFamily: "MinionVariableConcept",
                fontSize: "17px",
                color: "#ffffff",
                cursor: "default",
                textAlign: "left",
              }}
            >
              High advantage in battles through their high hp, attack, skills.
            </Typography>
            <Grid
              container
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <Grid item md={6} sm={12} xs={12} sx={{ px: 2 }}>
                <Typography
                  sx={{
                    fontFamily: "MinionVariableConcept",
                    fontSize: "20px",
                    color: "#f4cd65",
                    mt: 1,
                    cursor: "default",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  Number of Chiefs
                </Typography>
              </Grid>
              <Grid item md={6} sm={6} xs={6} sx={{ px: 2 }}>
                <IconButton onClick={decreaseChiefs}>
                  <RemoveCircleIcon color="primary" fontSize="small" />{" "}
                  {/* add on click function to decrease value */}
                </IconButton>
                <TextField
                  variant="standard"
                  id="number"
                  type="number"
                  value={noOfChiefs}
                  sx={{
                    "& .MuiInputBase-input": {
                      textAlign: "center",
                    },
                    "& .MuiInputBase-root": {
                      borderTop: "0px",
                      borderBottom: "1px solid rgb(244, 205, 101)",
                      borderRadius: "0px",
                      p: 0,
                      textAlign: "center",
                    },
                  }}
                />
                <IconButton onClick={increaseChiefs}>
                  <AddCircleIcon color="primary" fontSize="small" />{" "}
                  {/* add on click function to increase value */}
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{ justifyContent: "center", alignItems: "center", mt: 5 }}
            >
              <Grid item md={6} sm={6} xs={6} sx={{ px: 2 }}>
                <Typography
                  sx={{
                    fontFamily: "MinionVariableConcept",
                    fontSize: "20px",
                    color: "#f4cd65",
                    mt: 1,
                    cursor: "default",
                    textAlign: "right",
                    fontWeight: "600",
                  }}
                >
                  Price:
                </Typography>
              </Grid>
              <Grid item md={6} sm={6} xs={6} sx={{ px: 2 }}>
                <Typography
                  sx={{
                    fontFamily: "MinionVariableConcept",
                    fontSize: "20px",
                    color: "#ffffff",
                    mt: 1,
                    cursor: "default",
                    textAlign: "left",
                  }}
                >
                  WETH 60
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                mt: 5,
              }}
            >
              <Grid item xs={4.25} sm={4.25} md={4.25} />
              <Grid item xs={3.5} sm={3.5} md={3.5}>
                <Box className="btn-border">
                  <Box
                    className="btn-inner"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      sx={{
                        letterSpacing: 1,
                        fontSize: 20,
                        p: 0,
                        pt: 0.5,
                        borderRadius: 0,
                      }}
                      onClick={MintChiefs}
                    >
                      Mint
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4.25} sm={4.25} md={4.25} />
            </Grid>
          </Grid>
          <Grid item md={6} sm={12} xs={12} sx={{ px: 2, height: "360px" }}>
            <Typography
              sx={{
                fontFamily: "MinionVariableConcept",
                fontSize: "26px",
                color: "#f4cd65",
                mt: 1,
                cursor: "default",
                textAlign: "left",
                fontWeight: "600",
              }}
            >
              Land Utility
            </Typography>
            <Typography
              sx={{
                mt: 1,
                minHeight: "80px",
                fontFamily: "MinionVariableConcept",
                fontSize: "17px",
                color: "#ffffff",
                cursor: "default",
                textAlign: "left",
              }}
            >
              Provides difterent boosts(as mentioned above) to the players.{" "}
              <br />
              Investors get profits on nav transactions performed by the players
              along with APR.
            </Typography>
            <Grid
              container
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <Grid item md={6} sm={6} xs={6} sx={{ px: 2 }}>
                <Typography
                  sx={{
                    fontFamily: "MinionVariableConcept",
                    fontSize: "20px",
                    color: "#f4cd65",
                    mt: 1,
                    cursor: "default",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  Land Size
                </Typography>
              </Grid>
              <Grid item md={6} sm={6} xs={6} sx={{ px: 2 }}>
                <IconButton onClick={decreaseLands}>
                  <RemoveCircleIcon color="primary" fontSize="small" />{" "}
                  {/* add on click function to decrease value */}
                </IconButton>
                <TextField
                  variant="standard"
                  id="number"
                  type="number"
                  value={landSize}
                  sx={{
                    "& .MuiInputBase-input": {
                      textAlign: "center",
                    },
                    "& .MuiInputBase-root": {
                      borderTop: "0px",
                      borderBottom: "1px solid rgb(244, 205, 101)",
                      borderRadius: "0px",
                      p: 0,
                      textAlign: "center",
                    },
                  }}
                />
                <IconButton onClick={increaseLands}>
                  <AddCircleIcon color="primary" fontSize="small" />{" "}
                  {/* add on click function to increase value */}
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{ justifyContent: "center", alignItems: "center", mt: 5 }}
            >
              <Grid item md={6} sm={6} xs={6} sx={{ px: 2 }}>
                <Typography
                  sx={{
                    fontFamily: "MinionVariableConcept",
                    fontSize: "20px",
                    color: "#f4cd65",
                    mt: 1,
                    cursor: "default",
                    textAlign: "right",
                    fontWeight: "600",
                  }}
                >
                  Price:
                </Typography>
              </Grid>
              <Grid item md={6} sm={6} xs={6} sx={{ px: 2 }}>
                <Typography
                  sx={{
                    fontFamily: "MinionVariableConcept",
                    fontSize: "20px",
                    color: "#ffffff",
                    mt: 1,
                    cursor: "default",
                    textAlign: "left",
                  }}
                >
                  WETH 60
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                mt: 5,
              }}
            >
              <Grid item xs={4.25} sm={4.25} md={4.25} />
              <Grid item xs={3.5} sm={3.5} md={3.5}>
                <Box className="btn-border">
                  <Box
                    className="btn-inner"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      sx={{
                        letterSpacing: 1,
                        fontSize: 20,
                        p: 0,
                        pt: 0.5,
                        borderRadius: 0,
                      }}
                      onClick={MintLands}
                    >
                      Mint
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4.25} sm={4.25} md={4.25} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
