import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Blackmarket from "./components/blackmarket/blackmarket";
import Footer from "./components/blackmarket/Footer";
import Profile from "./components/blackmarket/profile";
import BlackmarketHeader from "./components/blackmarket/blackmarket-header";
import BlockzDetail from "./components/blackmarket/blockzDetail";
import AccountHistory from "./components/blackmarket/Account-History";
import PayoutsStatus from "./components/blackmarket/Payouts-Status";
import StakingContainer from "./components/blackmarket/stakingContainer";
import Whitelisting from "./components/blackmarket/Whitelisting";
import Minting from "./components/blackmarket/Minting";
import ComingSoon from "./components/blackmarket/ComingSoon";

// Material
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "src/styles/BaseTheme";
// web3
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import Dashboard from "./components/blackmarket/Dashboard";
import Collection from "./components/blackmarket/Collection";
import axios from "axios";

function getLibrary(provider: any, connector: any) {
  return new Web3(provider);
}

function App() {
  axios.defaults.baseURL = "https://vemp-marketplace.frag-games.com";
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Web3ReactProvider getLibrary={getLibrary}>
          <div className="App bg-position">
            <Router /* basename="blackmarket" */>
              <BlackmarketHeader />
              <Switch>
                <Route path="/dashboard">
                  <Dashboard />
                </Route>
                <Route path="/blackmarket">
                  <Blackmarket />
                </Route>
                <Route path="/collection">
                  <Collection />
                </Route>
                <Route path="/accounthistory">
                  <AccountHistory />
                </Route>
                <Route path="/payoutsstatus">
                  <PayoutsStatus />
                </Route>
                <Route path="/minting">
                  <Minting />
                </Route>
                <Route path="/staking">
                  <ComingSoon />
                </Route>

                <Route path="/nft/:id">
                  <BlockzDetail />
                </Route>
                <Route path="/kingdoms/:id">
                  <BlockzDetail />
                </Route>
                <Route path="/camels/:id">
                  <BlockzDetail />
                </Route>

                <Route path="/user/:id">
                  <Profile />
                </Route>
                <Route path="/">
                  <Blackmarket />
                </Route>
              </Switch>
              {/* <Footer /> */}
            </Router>
          </div>
        </Web3ReactProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
