import * as React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Contracts from "src/contractData/contracts";
import { useHistory } from "react-router-dom";
import axios from "axios";
import DynamicCard from "./dynamicCard";

const TopSalesCards = (props: any) => {
  let history = useHistory();

  const [topSalesCards, setTopSalesCards] = React.useState([]);
  React.useEffect(() => {
    let offset = 0;
    let pageSize = 15;
    let url: string;
    url = `http://3.230.145.54:8008/api/listForSale/get-all-collection/${offset}/${pageSize}`;
    axios
      .post(url, {
        contractAddress: "0x96237601C1252ea21f0A03b38Acc032752Dc81b7",
        chiefsRarity: ["Common", "Rare", "Epic", "Legendary"],
        chiefClasses: [
          "Swords Man",
          "Archer",
          "Fire Thrower",
          "Javelin Thrower",
        ],
        chiefsSkill: [1, 100],
        chiefsLevel: [1, 100],
        price: [0, 1000],
      })
      .then(function (response: any) {
        setTopSalesCards(response?.data?.nfts?.data.slice(0, 5) || []);
      });
  }, []);

  function handleClick() {
    if (props.type?.toLowerCase() === Contracts.CHIEF?.toLowerCase()) {
      history.push({
        pathname: "/chiefs/" + props.nft._id,
        state: { card: props, type: props.type },
      });
    }
  }

  return (
    <React.Fragment>
      <Container>
        <Grid container>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              pt: 3,
            }}
          >
            <Typography
              align="left"
              sx={{
                fontSize: "17px",
                color: "#ffeaf2",
                ml: 6,
                fontFamily: "Montserrat, medium, sans-serif",
              }}
            >
              Top Sales
            </Typography>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            sm={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pb: 1,
              ml: 2,
            }}
          >
            <Divider sx={{ borderColor: "#a26f74", width: "94%" }} />
          </Grid>
        </Grid>

        {/* <Box
          sx={{
            display: "flex",
            //   flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // pt: 2,
          }}
        > */}
        <Grid
          container
          justifyContent="center"
          columnSpacing={{ xs: 1, sm: 2, md: 1 }}
          //   rowSpacing={{ xs: 2, sm: 3, md: 4 }}
          alignItems="center"
        >
          <Grid
            item
            md={2.4}
            sm={12}
            xs={12}
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              //   ml: 53.5,
            }}
          >
            {topSalesCards.length &&
              topSalesCards.map((card: any, index) => {
                return (
                  <DynamicCard
                    type={Contracts.CHIEF.toLowerCase()}
                    card={card}
                  />
                );
              })}

            {/* <div className="nft-container card-corners">
              <Card className="nft-card card-corners" onClick={handleClick}>
                <CardActionArea>
                  <CardContent sx={{ height: "334px", p: 0 }}>
                    <Typography
                      variant="h6"
                      sx={{
                        backgroundImage:
                          "linear-gradient(180deg, rgb(228,218,216) 0%, rgb(228,218,216) 30%, rgb(182,166,160) 50%)",
                        WebkitBackgroundClip: "text",
                        p: 0,
                        pb: 0,
                      }}
                    >
                      <Box>
                        <Grid container spacing={1}>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography
                              noWrap
                              align="center"
                              sx={{
                                fontSize: "15px",
                                color: "white !important",
                              }}
                            >
                              Nft Id: 12345678
                            </Typography>

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Divider
                                sx={{
                                  borderColor: "white",
                                  width: "50%",
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Box
                              component="div"
                              sx={{
                                ml: 1,
                                mr: 1,
                                display: "flex",
                                overflow: "hidden",
                                justifyContent: "center",
                                alignItems: "center",
                                my: 0.5,
                              }}
                            >
                              <img
                                src={Background}
                                style={{
                                  flex: "none",
                                  width: "161px",
                                  height: "214px",
                                }}
                              ></img>
                            </Box>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography
                              align="center"
                              sx={{
                                mr: 1,
                                "& .MuiTypography-root": {
                                  color: "#f4cd65 !important",
                                  fontSize: "18px",
                                  //   pt: 1,
                                },
                              }}
                            >
                              Character Name
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Divider
                              sx={{
                                borderColor: "#f4cd65",
                                borderWidth: "1px",
                              }}
                            />
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography
                              align="center"
                              sx={{
                                mr: 1,
                                "& .MuiTypography-root": {
                                  color: "#f4cd65 !important",
                                  fontSize: "30px",
                                },
                              }}
                            >
                              $ 88.99
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div> */}
          </Grid>

          {/* <Grid
            item
            md={3}
            sm={12}
            xs={12}
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="nft-container card-corners">
              <Card className="nft-card card-corners">
                <CardActionArea>
                  <CardContent sx={{ height: "334px", p: 0 }}>
                    <Typography
                      variant="h6"
                      sx={{
                        backgroundImage:
                          "linear-gradient(180deg, rgb(228,218,216) 0%, rgb(228,218,216) 30%, rgb(182,166,160) 50%)",
                        WebkitBackgroundClip: "text",
                        p: 0,
                        pb: 0,
                      }}
                    >
                      <Box>
                        <Grid container spacing={1}>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography
                              noWrap
                              align="center"
                              sx={{
                                // width: "90px",
                                fontSize: "15px",
                                // px: 1,
                                color: "white !important",
                                // textOverflow: "ellipsis",
                              }}
                            >
                              Nft Id: 12345678
                            </Typography>

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Divider
                                sx={{
                                  borderColor: "white",
                                  width: "50%",
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Box
                              component="div"
                              sx={{
                                ml: 1,
                                mr: 1,
                                display: "flex",
                                overflow: "hidden",
                                justifyContent: "center",
                                alignItems: "center",
                                my: 0.5,
                              }}
                            >
                              <img
                                src={Background}
                                style={{
                                  flex: "none",
                                  width: "161px",
                                  height: "214px",
                                }}
                              ></img>
                            </Box>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography
                              align="center"
                              sx={{
                                mr: 1,
                                "& .MuiTypography-root": {
                                  color: "#f4cd65 !important",
                                  fontSize: "18px",
                                  //   pt: 1,
                                },
                              }}
                            >
                              Character Name
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Divider
                              sx={{
                                borderColor: "#f4cd65",
                                borderWidth: "1px",
                              }}
                            />
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography
                              align="center"
                              sx={{
                                mr: 1,
                                "& .MuiTypography-root": {
                                  color: "#f4cd65 !important",
                                  fontSize: "30px",
                                  //   pt: 1,
                                },
                              }}
                            >
                              $ 88.99
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          </Grid>

          <Grid
            item
            md={3}
            sm={12}
            xs={12}
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="nft-container card-corners">
              <Card className="nft-card card-corners">
                <CardActionArea>
                  <CardContent sx={{ height: "334px", p: 0 }}>
                    <Typography
                      variant="h6"
                      sx={{
                        backgroundImage:
                          "linear-gradient(180deg, rgb(228,218,216) 0%, rgb(228,218,216) 30%, rgb(182,166,160) 50%)",
                        WebkitBackgroundClip: "text",
                        p: 0,
                        pb: 0,
                      }}
                    >
                      <Box>
                        <Grid container spacing={1}>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography
                              noWrap
                              align="center"
                              sx={{
                                // width: "90px",
                                fontSize: "15px",
                                // px: 1,
                                color: "white !important",
                                // textOverflow: "ellipsis",
                              }}
                            >
                              Nft Id: 12345678
                            </Typography>

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Divider
                                sx={{
                                  borderColor: "white",
                                  width: "50%",
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Box
                              component="div"
                              sx={{
                                ml: 1,
                                mr: 1,
                                display: "flex",
                                overflow: "hidden",
                                justifyContent: "center",
                                alignItems: "center",
                                my: 0.5,
                              }}
                            >
                              <img
                                src={Background}
                                style={{
                                  flex: "none",
                                  width: "161px",
                                  height: "214px",
                                }}
                              ></img>
                            </Box>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography
                              align="center"
                              sx={{
                                mr: 1,
                                "& .MuiTypography-root": {
                                  color: "#f4cd65 !important",
                                  fontSize: "18px",
                                  //   pt: 1,
                                },
                              }}
                            >
                              Character Name
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Divider
                              sx={{
                                borderColor: "#f4cd65",
                                borderWidth: "1px",
                              }}
                            />
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography
                              align="center"
                              sx={{
                                mr: 1,
                                "& .MuiTypography-root": {
                                  color: "#f4cd65 !important",
                                  fontSize: "30px",
                                  //   pt: 1,
                                },
                              }}
                            >
                              $ 88.99
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          </Grid>

          <Grid
            item
            md={3}
            sm={12}
            xs={12}
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="nft-container card-corners">
              <Card className="nft-card card-corners">
                <CardActionArea>
                  <CardContent sx={{ height: "334px", p: 0 }}>
                    <Typography
                      variant="h6"
                      sx={{
                        backgroundImage:
                          "linear-gradient(180deg, rgb(228,218,216) 0%, rgb(228,218,216) 30%, rgb(182,166,160) 50%)",
                        WebkitBackgroundClip: "text",
                        p: 0,
                        pb: 0,
                      }}
                    >
                      <Box>
                        <Grid container spacing={1}>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography
                              noWrap
                              align="center"
                              sx={{
                                // width: "90px",
                                fontSize: "15px",
                                // px: 1,
                                color: "white !important",
                                // textOverflow: "ellipsis",
                              }}
                            >
                              Nft Id: 12345678
                            </Typography>

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Divider
                                sx={{
                                  borderColor: "white",
                                  width: "50%",
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Box
                              component="div"
                              sx={{
                                ml: 1,
                                mr: 1,
                                display: "flex",
                                overflow: "hidden",
                                justifyContent: "center",
                                alignItems: "center",
                                my: 0.5,
                              }}
                            >
                              <img
                                src={Background}
                                style={{
                                  flex: "none",
                                  width: "161px",
                                  height: "214px",
                                }}
                              ></img>
                            </Box>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography
                              align="center"
                              sx={{
                                mr: 1,
                                "& .MuiTypography-root": {
                                  color: "#f4cd65 !important",
                                  fontSize: "18px",
                                  //   pt: 1,
                                },
                              }}
                            >
                              Character Name
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Divider
                              sx={{
                                borderColor: "#f4cd65",
                                borderWidth: "1px",
                              }}
                            />
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Typography
                              align="center"
                              sx={{
                                mr: 1,
                                "& .MuiTypography-root": {
                                  color: "#f4cd65 !important",
                                  fontSize: "30px",
                                  //   pt: 1,
                                },
                              }}
                            >
                              $ 88.99
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          </Grid> */}
        </Grid>
        {/* </Box> */}
      </Container>
    </React.Fragment>
  );
};

export default TopSalesCards;
