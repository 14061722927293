import React from "react";
import Slider from "@mui/material/Slider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "@fontsource/montserrat";

export default function SliderFilter(props: any) {
  const handlePriceChange = (event: Event, newValue: number | number[]) => {
    props.setPriceFilter(newValue as number[]);
  };
  const handlePriceChangeCommitted = (
    event: Event | React.SyntheticEvent,
    newValue: number | number[]
  ) => {
    console.log(newValue);
    props.setPriceFilterCommitted(newValue as number[]);
  };

  return (
    <div>
      <Grid container>
        <Grid item md={6} sm={6} xs={6}>
          <Typography
            sx={{
              textAlign: "left",
              fontFamily: "Montserrat, medium, sans-serif",
              // m: 5,
            }}
          >
            {props.priceFilter[0]}
          </Typography>
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <Typography
            sx={{
              textAlign: "right",
              ml: 2,
              fontFamily: "Montserrat, medium, sans-serif",
            }}
          >
            {props.priceFilter[1]}
          </Typography>
        </Grid>
        <Grid item md={12} sm={12} xs={12} sx={{ mt: -3.5, ml: -1 }}>
          <Slider
            aria-label="Custom marks"
            value={props.priceFilter}
            max={props.maxPrice}
            onChangeCommitted={handlePriceChangeCommitted}
            onChange={handlePriceChange}
            color="primary"
            // valueLabelDisplay="off"
            sx={{
              mr: 1,
              // ml: 1,
              width: "100px",
              fontFamily: "Montserrat, medium, sans-serif",
              fontSize: "15px",
              "& .MuiSlider-thumb": {
                height: 10,
                width: 10,
                backgroundColor: "#ffffff",
                border: "1px solid #a26f74",
                transform: "rotate(45deg)",
                mt: -0.6,
                borderRadius: 0,
                "&:before": {
                  display: "none",
                },
              },
              "& .MuiSlider-valueLabel": {
                fontSize: "12px",
                fontWeight: "normal",
                top: 25,
                transform: "rotate(-45%)",
                backgroundColor: "unset",
                "&:before": {
                  display: "none",
                },
                "& *": {
                  background: "transparent",
                },
              },
              "& .MuiSlider-rail": {
                backgroundColor: "#9a2c25",
              },
              "& .MuiSlider-track": {
                backgroundColor: "#ffeaf2",
                border: "0px solid #ffeaf2",
              },
              "&:last-child": {},
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
