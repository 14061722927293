import React from "react";
import MarketplaceContent from "./marketplaceContent";
import { useLocation } from "react-router-dom";
import { Box, CssBaseline, Typography } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Collection = (prop: any) => {
  var sectionStyle = {
    marginTop: 0,
    color: "primary",
  };

  const [value, setValue] = React.useState(1);
  const location: any = useLocation();
  const [type] = React.useState(location.state?.type);

  return (
    <React.Fragment>
      <CssBaseline />

      <div style={sectionStyle} className="pageMaxSize">
        <TabPanel value={value} index={1}>
          <MarketplaceContent
            type="Character"
            path="collection"
            key1={"nftByCollection"}
          />
        </TabPanel>
      </div>
    </React.Fragment>
  );
};

export default Collection;
