import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { Container, TextField, Typography } from "@mui/material";
import { useAuth, useContract } from "../hooks/web3Auth";
import BlackmarketContract from "../../contractData/blackmarektabi";
import Vemp from "src/assets/antara-exports/MainMenu/Vemp.png";
import Button from "@mui/material/Button";
import BigNumber from "bignumber.js";
import Tooltip from "@mui/material/Tooltip";
import DetailCard from "../card/DetailCard";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
// import { validChains } from "../hooks/web3Auth";
import "@fontsource/montserrat";
import GameManager from "../../contractData/gameManagerabi";

export default function BuyAndSell(props: any) {
  const { chainId } = useWeb3React();
  const { account, login, validChains } = useAuth();
  const { contract: nftContract, methods: nftMethods } = useContract(
    BlackmarketContract.abi,
    // props.contractAddress
    props.cards?.nft.collectionId.contractAddress
  );

  // console.log("NFTMETHODS:", props.cards.nft.collectionId.contractAddress);

  const [usdPrice, setUsdPrice] = React.useState(1);
  const [isValidChain, setIsValidChain] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (chainId !== 97 && chainId !== 56) {
      setIsValidChain(true);
    } else {
      setIsValidChain(false);
    }

    axios
      .get(
        "https://api.coingecko.com/api/v3/simple/price?ids=vempire-ddao&vs_currencies=usd"
      )
      .then(function (response: any) {
        let dollarPrice: number =
          response?.data["vempire-ddao"]?.usd * props?.cards?.price;
        console.log("USD: ", dollarPrice);
        setUsdPrice(dollarPrice);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [chainId]);

  const onEditListing = async (action: any) => {
    props.setBackdrop(true);
    let isApproved = await nftMethods
      .isApprovedForAll(account, GameManager.address)
      .call();
    // console.log("Address:" + GameManager.address);

    console.log("is approved:" + isApproved);
    if (
      !isApproved &&
      account !== "0x0E5De84bFC1A9799a0FdA4eF0Bd13b6A20e97d89"
    ) {
      nftMethods
        .setApprovalForAll(GameManager.address, true)
        .send({
          from: account,
          gasPrice: parseInt(`${new BigNumber(10).pow(10)}`),
        })
        .on("transactionHash", (tx: string) => {
          console.log("hash", tx);
        })
        .on("receipt", (rc: any) => {
          console.log("receipt", rc);
          action(true);
        })
        .on("error", (error: any, receipt: any) => {
          console.log("error", error, receipt);
          props.setBackdrop(false);
        });
    } else {
      action(true);
    }
  };
  // console.log(props.cards.status);
  if (account) {
    console.log(account);
    if (
      props.cards?.ownerAddress?.toLowerCase() === account?.toLowerCase() ||
      props.ownerAddress?.toLowerCase() === account?.toLowerCase()
    ) {
      console.log(props.cards?.ownerAddress);
      console.log(props.ownerAddress);
      console.log("ownerAddress matched");
      if (props.cards?.status === "listed") {
        return (
          <>
            <DetailCard
              sx={{
                width: "450px",
                py: 3,
                px: 4,
                display: "flex",
                justifyContent: "space-around",
                alignItems: "space-around",
              }}
            >
              <Grid container spacing={5}>
                <Grid item md={6}>
                  <Box
                    className="btn-border"
                    sx={
                      {
                        // marginTop: "10px",
                        // marginRight: "10px",
                      }
                    }
                  >
                    <Box
                      className="btn-inner"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        disabled={props.loading}
                        sx={{
                          fontFamily: "Playfair Display SC, serif",
                          color: "#ffffff",
                          // fontSize: "13px",
                        }}
                        onClick={() => {
                          onEditListing(props.editListing);
                        }}
                      >
                        Edit Listing
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box
                    className="btn-border"
                    sx={
                      {
                        // marginTop: "10px",
                        // marginRight: "10px",
                      }
                    }
                  >
                    <Box
                      className="btn-inner"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        sx={{
                          fontFamily: "Playfair Display SC, serif",
                          color: "#ffffff",
                          // fontSize: "15px",
                        }}
                        onClick={props.cancelListing}
                        disabled={props.loading}
                        // sx={{ ml: 2 }}
                      >
                        Cancel Listing
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </DetailCard>
          </>
        );
      } else {
        return (
          <>
            <DetailCard sx={{ width: "450px", p: 2 }}>
              <Typography
                align="left"
                sx={{
                  fontFamily: "Montserrat, medium, sans-serif",
                  color: "#a26f74",
                  fontSize: "20px",
                  py: 1,
                }}
              >
                List Nft For Sale
              </Typography>

              <TextField
                // autoFocus
                required
                size="small"
                margin="dense"
                id="name"
                label="Price"
                type="number"
                fullWidth
                // error={props.listingPrice < 0}
                value={props.listingPrice}
                onChange={props.handlePriceChange}
                inputProps={{
                  pattern: "[0-9]*",
                  min: 0,
                  style: {
                    fontFamily: "Montserrat, medium, sans-serif",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "#ffffff",
                    fontFamily: "Montserrat, medium, sans-serif",
                  },
                }}
                sx={{
                  "& .MuiInputBase-input": {
                    color: "#ffffff !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#a26f74",
                    },
                    "&:hover fieldset": {
                      borderColor: "#ffffff",
                    },
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#ffffff",
                    },
                  fontFamily: "Montserrat, medium, sans-serif",
                  color: "#ffffff",
                  borderRadius: "0px",
                  mb: 2,
                }}
              />
              {props.listingPrice > 9999 ? (
                <Typography
                  color="#c82f2f"
                  align="left"
                  sx={{
                    mt: -1.5,
                    fontSize: "11px",
                    fontFamily: "Montserrat, medium, sans-serif",
                  }}
                >
                  Price should be lesser than 9999
                </Typography>
              ) : null}
              {props.listingPrice < 1 ? (
                <Typography
                  color="#c82f2f"
                  align="left"
                  sx={{
                    mt: -1.5,
                    fontSize: "11px",
                    fontFamily: "Montserrat, medium, sans-serif",
                  }}
                >
                  Price should be greater than 0
                </Typography>
              ) : null}
              <Box
                className="btn-border"
                sx={{
                  ml: 15,
                }}
              >
                <Box
                  className="btn-inner"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    disabled={
                      props.listingPrice === "unlisted" ||
                      props.listingPrice < 1 ||
                      props.listingPrice > 9999 ||
                      isValidChain
                    }
                    sx={{
                      color: "#ffffff",
                      border: "none",
                      borderRadius: 0,
                      fontFamily: "Playfair Display SC, serif",
                      fontSize: "15px",
                      "&.Mui-disabled": { color: "#200105" },
                    }}
                    onClick={() => {
                      onEditListing(props.action);
                    }}
                  >
                    List For Sale
                  </Button>
                </Box>
              </Box>
            </DetailCard>
          </>
        );
      }
    } else {
      console.log(props.cards?.status);
      if (props.cards?.status === "listed") {
        console.log("ownerAddress not matched");
        return (
          <>
            <DetailCard sx={{ width: "450px" }}>
              <Typography
                align="left"
                sx={{
                  px: 1,
                  fontSize: "19px",
                  fontFamily: "Montserrat, medium, sans-serif",
                  color: "#a26f74",
                }}
              >
                Price
              </Typography>
              <Grid
                container
                spacing={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  md={6}
                  textAlign="center"
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item pr={1} pt={0.2}>
                    <Avatar
                      variant="square"
                      alt="test avatar"
                      src={Vemp}
                      sx={{ height: "20px", width: "20px" }}
                    />
                  </Grid>
                  <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                    <Typography
                      sx={{
                        pr: 0.5,
                        pt: 1,
                        fontSize: "22px",
                        fontFamily: "Montserrat, medium, sans-serif",
                        color: "#e5d161",
                      }}
                    >
                      {props.cards?.price?.toPrecision(4)}
                    </Typography>

                    <Typography
                      sx={{
                        // pr: 1,
                        mt: 2.25,
                        fontSize: "12px",
                        color: "#ffffff",
                        fontFamily: "Montserrat, medium, sans-serif",
                      }}
                    >
                      (${usdPrice?.toPrecision(3)})
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {isValidChain ? (
                    <Tooltip title="Please connect the valid network">
                      <Box className="btn-border">
                        <Box
                          className="btn-inner"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            disabled={isValidChain}
                            sx={{
                              letterSpacing: 1,
                              fontFamily: "Playfair Display SC, serif",
                              fontSize: "22px",
                              p: 0,
                              px: 4,
                              pt: 0.5,
                              borderRadius: 0,
                              width: "162px",
                              color: "#ffffff",
                            }}
                            onClick={props.buyNFT}
                          >
                            Buy
                          </Button>
                        </Box>
                      </Box>
                    </Tooltip>
                  ) : (
                    <Box className="btn-border">
                      <Box
                        className="btn-inner"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          disabled={isValidChain}
                          sx={{
                            letterSpacing: 1,
                            fontFamily: "Playfair Display SC, serif",
                            fontSize: "22px",
                            p: 0,
                            px: 4,
                            pt: 0.5,
                            borderRadius: 0,
                            width: "162px",
                            color: "#ffffff",
                          }}
                          onClick={props.buyNFT}
                        >
                          Buy
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </DetailCard>
          </>
        );
      } else {
        return (
          <Typography sx={{ fontFamily: "Montserrat, medium, sans-serif" }}>
            Not Currently Listed
          </Typography>
        );
      }
    }
  } else {
    console.log(props.cards?.status);
    if (props.cards?.status === "listed") {
      console.log("ownerAddress not matched");
      return (
        <>
          <DetailCard sx={{ width: "450px" }}>
            <Typography
              align="left"
              sx={{
                px: 1,
                fontSize: "19px",
                fontFamily: "Montserrat, medium, sans-serif",
                color: "#a26f74",
              }}
            >
              Price
            </Typography>
            <Grid
              container
              spacing={1}
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Grid
                item
                md={6}
                textAlign="center"
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item pr={1} pt={0.2}>
                  <Avatar
                    variant="square"
                    alt="test avatar"
                    src={Vemp}
                    sx={{ height: "20px", width: "20px" }}
                  />
                </Grid>
                <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      pr: 0.5,
                      pt: 1,
                      fontSize: "22px",
                      fontFamily: "Montserrat, medium, sans-serif",
                      color: "#e5d161",
                    }}
                  >
                    {props.cards?.price}
                  </Typography>
                  <Typography
                    sx={{
                      // pr: 1,
                      mt: 2.25,
                      fontSize: "12px",
                      color: "#ffffff",
                      fontFamily: "Montserrat, medium, sans-serif",
                    }}
                  >
                    (${usdPrice?.toPrecision(2)})
                  </Typography>
                </Grid>
              </Grid>
              <Grid item md={6} sx={{ display: "flex", alignItems: "center" }}>
                <Tooltip title="Please Login to Buy">
                  <Box className="btn-border">
                    <Box
                      className="btn-inner"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        sx={{
                          lineHeight: 1.4,
                          letterSpacing: 1,
                          fontFamily: "Playfair Display SC, serif",
                          fontSize: 24,
                          p: 0,
                          px: 4,
                          borderRadius: 0,
                          cursor: "not-allowed",
                          color: "#ffffff",
                        }}
                        onClick={props.buyNFT}
                      >
                        Buy
                      </Button>
                    </Box>
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </DetailCard>
        </>
      );
    } else {
      return (
        <Typography sx={{ fontFamily: "Montserrat, medium, sans-serif" }}>
          Not Currently Listed
        </Typography>
      );
    }
  }
}
