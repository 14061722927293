import * as React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import ButtonBase from "@mui/material/ButtonBase";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useAuth, useEagerConnect } from "../hooks/web3Auth";
import DynamicMenu from "../userMenus/dynamicMenu";
import NavButton from "../buttons/navButton";
import WarringTribesLogo from "../../assets/antara-exports/MainMenu/WarringTribesLogo.png";
import DiscordIcon from "../../assets/antara-exports/MainMenu/Discord.png";
import TwitterIcon from "../../assets/antara-exports/MainMenu/Twitter.png";
import TelegramIcon from "../../assets/antara-exports/MainMenu/Telegram.png";
import { Typography } from "@mui/material";
import "@fontsource/montserrat";

// let url = window.open("https://discord.gg/vemp");

let styles = {
  width: "40px",
  height: "auto",
};
let twitterS = {
  width: "33px",
  height: "auto",
};
const pages = [
  {
    name: "Home",
    route:
      "http://warringtribes-landing-page.s3-website-us-east-1.amazonaws.com/",
  },
  {
    name: "Collection",
    route: "/collection",
  },
  {
    name: "Marketplace",
    route: "/",
  },
  {
    name: "Social",
    // icon: TwitterIcon,
    discord: "https://discord.gg/vemp",
    twitter: "https://twitter.com/WarringTribesgg",
    telegram: "https://t.me/VEMPCommunityHub/320523",
  },
];

export default function BlackmarketHeader() {
  let history = useHistory();
  let location = useLocation();
  console.log(location.pathname);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleNavMenuClick = (key: string) => {
    console.log(key);
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleNavClick = (route: any) => {
    console.log("inside nav click");
    history.push("/" + route.route);
  };
  const logoH = "118px";
  const logoW = "133px";

  useEagerConnect();
  const { account } = useAuth();
  const settings = [
    {
      name: "Profile",
      route: "/user/" + account,
    },
    {
      name: "Account History",
      route: "/accounthistory",
    },
    {
      name: "Payouts",
      route: "/payoutsstatus",
    },
    // {
    //   name: "Public Minting",
    //   route: "/minting",
    // },
  ];

  let getNavButtonMobile = (page: any) => {
    if (page.name == "Social") {
      return (
        <div>
          <ButtonBase
            disableRipple
            disableTouchRipple
            sx={{ cursor: "pointer", ml: 1 }}
            href={page.twitter}
          >
            <img src={TwitterIcon} style={twitterS} />
          </ButtonBase>
          <ButtonBase
            disableRipple
            disableTouchRipple
            sx={{ cursor: "pointer" }}
            href={page.discord}
          >
            <img src={DiscordIcon} style={styles} />
          </ButtonBase>
          <ButtonBase
            disableRipple
            disableTouchRipple
            sx={{ cursor: "pointer" }}
            href={page.telegram}
          >
            <img src={TelegramIcon} style={styles} />
          </ButtonBase>
        </div>
      );
    } else {
      return (
        <MenuItem
          disableRipple
          //disableTouchRipple
          key={page.name}
          onClick={() => {
            history.push(page.route);
          }}
          sx={{ color: "white" }}
        >
          <Typography
            textAlign="center"
            sx={{
              fontFamily: "Playfair Display SC",
            }}
          >
            {page.name}
          </Typography>
        </MenuItem>
      );
    }
  };

  return (
    <>
      <AppBar
        position="absolute"
        sx={{
          backgroundColor: "transparent",
          height: "135px",
          minHeight: "55px !important",
          boxShadow: "none",
        }}
      >
        <Toolbar disableGutters sx={{ alignItems: "start" }}>
          {/* <Typography
                        variant="h4"
                        noWrap
                        component="div"
                        sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                    >
                        LOGO
                    </Typography> */}
          {/* <Link to="/">
            <ButtonBase
              sx={{
                mr: 2,
                pl: 8,
                mt: 2,
                display: { xs: "none", md: "flex" },
                "& .MuiTouchRipple-root": {
                  color: "transparent",
                },
              }}
            >
              <img
                src={Logo}
                width={logoW}
                height={logoH}
                alt="DopeWarz Logo Header"
              />
            </ButtonBase>
          </Link> */}

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              // width: "80px",
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "white" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                "& .MuiPaper-root": {
                  background:
                    "linear-gradient(180deg, rgba(51,26,29,0.8) 20%, rgba(56,16,19,0.8) 50%)",
                },
                width: "300px",
                fontFamily: "Montserrat, medium, sans-serif",
              }}
            >
              {/*  <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                onClick={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                  "& .MuiPaper-root": {
                    background:
                      "linear-gradient(180deg, rgba(51,26,29,0.8) 20%, rgba(56,16,19,0.8) 50%)",
                  },
                }}
              >
                {pages.map((page, index) => getNavButtonMobile(page))}
              </Menu> */}
              {pages.map((page, index) => getNavButtonMobile(page))}
            </Menu>
          </Box>
          {/* <Link to="/">
            <ButtonBase
              component="div"
              style={{ justifyContent: "flex-start" }}
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
                "& .MuiTouchRipple-root": {
                  color: "transparent",
                },
              }}
            >
              <img
                src={Logo}
                width={logoW}
                height={logoH}
                alt="DopeWarz Logo Header"
              />
            </ButtonBase>
          </Link> */}

          <Box
            sx={{
              pt: 3,
              px: 10,
              display: {
                xs: "none",
                md: "flex",
              },
            }}
          >
            <img src={WarringTribesLogo} width="120vw" height="auto"></img>
          </Box>
          <Box
            sx={{
              cursor: "pointer !important",
              flexGrow: 1,
              display: {
                xs: "none",
                md: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
            }}
            className="header"
          >
            {pages.map((page, index) => (
              <NavButton currentPage={page} location={location} key={index} />
            ))}
          </Box>

          <DynamicMenu settings={settings} />
        </Toolbar>
      </AppBar>
      <Box sx={{ height: "50px" }}></Box>
    </>
  );
}
