import * as React from "react";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import axios from "axios";
import { useAuth } from "../hooks/web3Auth";
import { TableFooter } from "@mui/material";
import DividerIcon from "src/assets/antara-exports/MainMenu/Title_Icon.png";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import TableSkeleton from "./Skeletons/TableSkeleton";
import leftDivider from "src/assets/antara-exports/MainMenu/left.png";
import rightDivider from "src/assets/antara-exports/MainMenu/right.png";
import "@fontsource/montserrat";

interface Column {
  id: "Name" | "TokenID" | "Date" | "Status" | "Price" | "PreviousOwner";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "Name", label: "Name", minWidth: 100 },
  { id: "TokenID", label: "Token ID", minWidth: 100 },
  { id: "Date", label: "Date", minWidth: 50 },
  {
    id: "Price",
    label: "Price",
    minWidth: 50,
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "PreviousOwner",
    label: "Previous Owner",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "Status",
    label: "Status",
    minWidth: 50,
  },
];

interface Data {
  Name: string;
  TokenID: string;
  Date: string;
  Status: string;
  Price: number;
  PreviousOwner: string;
}

function createData(
  Name: string,
  TokenID: string,
  Date: string,
  Status: string,
  Price: number,
  PreviousOwner: string
): Data {
  return { Name, TokenID, Date, Status, Price, PreviousOwner };
}

export default function PayoutsStatus() {
  const [page, setPage] = React.useState(0);
  // const [offset, setOffset] = React.useState(0);
  const [count, setCount] = React.useState(0);

  const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { account } = useAuth();
  const [Loading, setLoading] = React.useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    console.log("PAGE:", page);
    // setOffset(rowsPerPage * page);
    // setPage(1);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // const a = parseInt(event.target.value);
    // console.log("ROWS: ", a);
    setRowsPerPage(+event.target.value);
    // setOffset(rowsPerPage * page);
    setPage(0);
  };

  React.useEffect(() => {
    const offset = page * rowsPerPage;
    // console.log("OFFSET", offset, "Page", page, "PerPage", rowsPerPage);
    let url: string;
    url = `/api/payouts/wallet/${offset}/${rowsPerPage}`;
    // url = `http://localhost:8008/api/payouts/wallet/${offset}/${rowsPerPage}`;
    let payload = {
      wallet: account,
    };
    axios
      .post(url, payload)
      .then(function (response: any) {
        console.log("Payouts", response);
        let data: any = [];
        setCount(response.data.payouts.count);
        response.data.payouts.data.map((pay: any) => {
          data.push(
            createData(
              pay?.Nft?.name,
              pay?.Nft?.tokenId.toString(), //nft.tokenId.toString(),
              new Date(pay.createdAt).toDateString(),
              pay.status,
              pay?.Transaction?.price,
              pay?.Transaction?.seller
            )
          );
        });
        console.log("setting rows", data);
        setRows(data);
        setLoading(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [account, page, rowsPerPage]);

  const getBorderColor = (status: any) => {
    if (status === "pending") {
      return "transparent";
    } else {
      return "transparent";
    }
  };
  return (
    <>
      <div className="pageMaxSize">
        <Box
          // direction="row"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pt: 4,
          }}
        >
          <img src={leftDivider} width="60px" style={{ marginTop: 20 }}></img>
          <Typography
            sx={{
              color: "#ffffff",
              fontSize: "22px",
              px: 1,
              fontFamily: "Montserrat, medium, sans-serif",
            }}
          >
            PAYOUTS
          </Typography>
          <img src={rightDivider} width="60px" style={{ marginTop: 20 }}></img>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Divider
            sx={{
              mb: 4,
              width: "100px",
              borderColor: "#ffffff !important",
              mt: -0.5,
            }}
          />
        </Box>

        <Card
          sx={{
            width: "80%",
            overflow: "hidden",
            ml: "10%",
            mr: "10%",
            border: "1px solid #ffffff",
            borderRadius: "0px",
            mt: 1,
            backgroundColor: "transparent !important",
            textAlign: "left",
          }}
        >
          {Loading ? (
            <TableContainer sx={{ cursor: "default" }}>
              <Table
                stickyHeader
                sx={{
                  ".MuiTableCell-root": {
                    borderBottom: "1px solid #4c2c20",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        sx={{
                          fontFamily: "Montserrat, medium, sans-serif",
                          minWidth: column.minWidth,
                          backgroundColor: "transparent",
                          borderColor: "#4c2c20",
                          color: "#ffffff",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.length ? (
                    rows.map(
                      (row: {
                        [x: string]: any;
                        TokenID: React.Key | null | undefined;
                      }) => {
                        // console.log("Print Row", rows);
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.TokenID}
                            sx={{
                              borderColor: "#4c2c20",
                              backgroundColor: "transparent",
                              height: "60px",
                            }}
                          >
                            {columns.map((column) => {
                              // console.log("COLUMN", column);
                              const value = row[column.id];
                              // console.log("vvvvv", row);
                              return (
                                <TableCell
                                  sx={{
                                    color: "#ffffff",
                                    fontFamily:
                                      "Montserrat, medium, sans-serif",
                                  }}
                                  // key={column.id}
                                >
                                  <Box
                                    sx={
                                      column.label == "Status"
                                        ? {
                                            border:
                                              "1px solid " +
                                              getBorderColor(value),
                                            borderRadius: "0px",
                                          }
                                        : {}
                                    }
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "13px",
                                        // margin: "1px",
                                        fontFamily:
                                          "Montserrat, medium, sans-serif",
                                      }}
                                    >
                                      {column.format &&
                                      typeof value === "number"
                                        ? column.format(value)
                                        : value}
                                      {/* {console.log("VALUE", value)} */}
                                    </Typography>
                                  </Box>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      }
                    )
                  ) : (
                    <></>
                  )}
                </TableBody>
                <TableFooter>
                  <TablePagination
                    sx={{
                      color: "#ffffff",
                      "& .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon": {
                        color: "#ffffff",
                      },
                      "& .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled":
                        { color: "#ffffff" },
                      "& .MuiTablePagination-selectLabel": {
                        fontFamily: "Montserrat, medium, sans-serif",
                      },
                      "& .MuiTablePagination-displayedRows": {
                        fontFamily: "Montserrat, medium, sans-serif",
                      },
                      "& .MuiInputBase-input.MuiSelect-select": {
                        fontFamily: "Montserrat, medium, sans-serif",
                      },
                      "& .MuiMenuItemRoot": {
                        fontFamily: "Montserrat, medium, sans-serif !important",
                      },
                      "& .MuiTablePagination-menuItem": {
                        fontFamily: "Montserrat, medium, sans-serif !important",
                      },
                    }}
                    rowsPerPageOptions={[10, 25, 100]}
                    // component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    SelectProps={{
                      MenuProps: {
                        sx: {
                          "& .MuiPaper-root": {
                            backgroundColor: "#2e060d",
                            color: "#ffffff",
                            fontFamily:
                              "Montserrat, medium, sans-serif !important",
                          },
                          "& .css-hgavz8-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem":
                            {
                              fontFamily:
                                "Montserrat, medium, sans-serif !important",
                            },
                          "& .MuiMenuItemRoot": {
                            fontFamily:
                              "Montserrat, medium, sans-serif !important",
                          },
                          "& .MuiTablePagination-menuItem": {
                            fontFamily:
                              "Montserrat, medium, sans-serif !important",
                          },
                        },
                      },
                    }}
                  />
                </TableFooter>
              </Table>
            </TableContainer>
          ) : (
            <TableSkeleton />
          )}
        </Card>
      </div>
    </>
  );
}
