import * as React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import DynamicCard from "./dynamicCard";
import Contracts from "src/contractData/contracts";

const RecentSalesCards = (props: any) => {
  const [topSalesCards, setTopSalesCards] = React.useState([]);
  React.useEffect(() => {
    let offset = 0;
    let pageSize = 15;
    let url: string;
    url = `http://3.230.145.54:8008/api/listForSale/get-all-collection/${offset}/${pageSize}`;
    axios
      .post(url, {
        contractAddress: "0x96237601C1252ea21f0A03b38Acc032752Dc81b7",
        chiefsRarity: ["Common", "Rare", "Epic", "Legendary"],
        chiefClasses: [
          "Swords Man",
          "Archer",
          "Fire Thrower",
          "Javelin Thrower",
        ],
        chiefsSkill: [1, 100],
        chiefsLevel: [1, 100],
        price: [0, 1000],
      })
      .then(function (response: any) {
        setTopSalesCards(response?.data?.nfts?.data.slice(5, 10) || []);
      });
  }, []);
  return (
    <React.Fragment>
      <Container>
        {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 2,
        }}
      > */}
        <Grid container>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              pt: 3,
            }}
          >
            <Typography
              align="left"
              sx={{
                fontSize: "17px",
                color: "#ffeaf2",
                ml: 6,
                fontFamily: "Montserrat, medium, sans-serif",
              }}
            >
              Recent Sales
            </Typography>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            sm={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pb: 1,
              ml: 2,
            }}
          >
            <Divider sx={{ borderColor: "#a26f74", width: "94%" }} />
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="center"
          columnSpacing={{ xs: 1, sm: 2, md: 1 }}
          //   rowSpacing={{ xs: 2, sm: 3, md: 4 }}
          alignItems="center"
        >
          <Grid
            item
            md={2.4}
            sm={12}
            xs={12}
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              //   ml: 53.5,
            }}
          >
            {topSalesCards.length &&
              topSalesCards.map((card: any, index) => {
                return (
                  <DynamicCard
                    type={Contracts.CHIEF.toLowerCase()}
                    card={card}
                  />
                );
              })}
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default RecentSalesCards;
