const GameManager = {
  abi: [
    {
      inputs: [
        { internalType: "contract ERC20", name: "_Vemp", type: "address" },
        { internalType: "contract ERC20", name: "_Xp", type: "address" },
        { internalType: "address", name: "_admin", type: "address" },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "bytes32",
          name: "role",
          type: "bytes32",
        },
        {
          indexed: true,
          internalType: "bytes32",
          name: "previousAdminRole",
          type: "bytes32",
        },
        {
          indexed: true,
          internalType: "bytes32",
          name: "newAdminRole",
          type: "bytes32",
        },
      ],
      name: "RoleAdminChanged",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "bytes32",
          name: "role",
          type: "bytes32",
        },
        {
          indexed: true,
          internalType: "address",
          name: "account",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "sender",
          type: "address",
        },
      ],
      name: "RoleGranted",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "bytes32",
          name: "role",
          type: "bytes32",
        },
        {
          indexed: true,
          internalType: "address",
          name: "account",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "sender",
          type: "address",
        },
      ],
      name: "RoleRevoked",
      type: "event",
    },
    {
      inputs: [],
      name: "ADMIN_ROLE",
      outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "DEFAULT_ADMIN_ROLE",
      outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "DIVISOR",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "MANAGER_ROLE",
      outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "Vemp",
      outputs: [{ internalType: "contract ERC20", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "XP",
      outputs: [{ internalType: "contract ERC20", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "collection", type: "address" },
      ],
      name: "addCollection",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
      name: "addRewardToPool",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "admin",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          components: [
            { internalType: "uint256", name: "parent1", type: "uint256" },
            { internalType: "uint256", name: "parent2", type: "uint256" },
            { internalType: "uint256", name: "child", type: "uint256" },
            { internalType: "string", name: "parent1Uri", type: "string" },
            { internalType: "string", name: "parent2Uri", type: "string" },
            { internalType: "string", name: "childUri", type: "string" },
            { internalType: "uint256", name: "amount", type: "uint256" },
            { internalType: "address", name: "token", type: "address" },
            { internalType: "address", name: "collection", type: "address" },
          ],
          internalType: "struct GameManager.BreedNftData",
          name: "breedNftData",
          type: "tuple",
        },
        { internalType: "bytes", name: "signature", type: "bytes" },
      ],
      name: "breedNft",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          components: [
            { internalType: "uint256", name: "id", type: "uint256" },
            { internalType: "uint256", name: "cost", type: "uint256" },
            { internalType: "string", name: "uri", type: "string" },
            { internalType: "address", name: "token", type: "address" },
            { internalType: "uint256", name: "burnId", type: "uint256" },
            { internalType: "address", name: "NFTContract", type: "address" },
          ],
          internalType: "struct GameManager.NFTDataBurn",
          name: "mintData",
          type: "tuple",
        },
        { internalType: "bytes", name: "signature", type: "bytes" },
      ],
      name: "burnAndMint",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "", type: "address" }],
      name: "collections",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "amount", type: "uint256" },
        { internalType: "bytes", name: "signature", type: "bytes" },
      ],
      name: "distributeReward",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "account", type: "address" },
        { internalType: "uint256", name: "nonce", type: "uint256" },
        {
          components: [
            { internalType: "uint256", name: "parent1", type: "uint256" },
            { internalType: "uint256", name: "parent2", type: "uint256" },
            { internalType: "uint256", name: "child", type: "uint256" },
            { internalType: "string", name: "parent1Uri", type: "string" },
            { internalType: "string", name: "parent2Uri", type: "string" },
            { internalType: "string", name: "childUri", type: "string" },
            { internalType: "uint256", name: "amount", type: "uint256" },
            { internalType: "address", name: "token", type: "address" },
            { internalType: "address", name: "collection", type: "address" },
          ],
          internalType: "struct GameManager.BreedNftData",
          name: "breedNftData",
          type: "tuple",
        },
      ],
      name: "getBreedDataHash",
      outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      stateMutability: "pure",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "account", type: "address" },
        { internalType: "uint256", name: "nonce", type: "uint256" },
        {
          components: [
            { internalType: "uint256", name: "id", type: "uint256" },
            { internalType: "uint256", name: "cost", type: "uint256" },
            { internalType: "string", name: "uri", type: "string" },
            { internalType: "address", name: "token", type: "address" },
            { internalType: "uint256", name: "burnId", type: "uint256" },
            { internalType: "address", name: "NFTContract", type: "address" },
          ],
          internalType: "struct GameManager.NFTDataBurn",
          name: "mintData",
          type: "tuple",
        },
      ],
      name: "getBurnMintDataHash",
      outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      stateMutability: "pure",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "account", type: "address" },
        { internalType: "uint256", name: "nonce", type: "uint256" },
        {
          components: [
            { internalType: "uint256", name: "id", type: "uint256" },
            { internalType: "string", name: "uri", type: "string" },
            { internalType: "address", name: "NFTContract", type: "address" },
          ],
          internalType: "struct GameManager.CardPackData[]",
          name: "cardPackData",
          type: "tuple[]",
        },
        {
          components: [
            { internalType: "address", name: "token", type: "address" },
            { internalType: "uint256", name: "cost", type: "uint256" },
          ],
          internalType: "struct GameManager.CardPackCost",
          name: "cardPackCost",
          type: "tuple",
        },
      ],
      name: "getCardPackDataHash",
      outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      stateMutability: "pure",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "account", type: "address" },
        { internalType: "uint256", name: "nonce", type: "uint256" },
        {
          components: [
            { internalType: "uint256", name: "id", type: "uint256" },
            { internalType: "uint256", name: "cost", type: "uint256" },
            { internalType: "string", name: "uri", type: "string" },
            { internalType: "address", name: "token", type: "address" },
          ],
          internalType: "struct GameManager.NFTData",
          name: "mintData",
          type: "tuple",
        },
      ],
      name: "getMintDataHash",
      outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      stateMutability: "pure",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "account", type: "address" }],
      name: "getNonce",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "account", type: "address" },
        { internalType: "uint256", name: "nonce", type: "uint256" },
        {
          components: [
            { internalType: "uint256", name: "amount", type: "uint256" },
            { internalType: "uint256", name: "burn", type: "uint256" },
            { internalType: "address", name: "payee", type: "address" },
            { internalType: "address", name: "token", type: "address" },
          ],
          internalType: "struct GameManager.RewardAndBurnData",
          name: "rewardAndBurnData",
          type: "tuple",
        },
      ],
      name: "getRewardAndBurnDataHash",
      outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      stateMutability: "pure",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "account", type: "address" },
        { internalType: "uint256", name: "nonce", type: "uint256" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "getRewardDataHash",
      outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      stateMutability: "pure",
      type: "function",
    },
    {
      inputs: [{ internalType: "bytes32", name: "role", type: "bytes32" }],
      name: "getRoleAdmin",
      outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "account", type: "address" },
        { internalType: "uint256", name: "nonce", type: "uint256" },
        {
          components: [
            { internalType: "address", name: "buyer", type: "address" },
            { internalType: "address", name: "seller", type: "address" },
            { internalType: "uint256", name: "amount", type: "uint256" },
            { internalType: "uint256", name: "tokenId", type: "uint256" },
            { internalType: "address", name: "token", type: "address" },
            { internalType: "uint256", name: "platformRatio", type: "uint256" },
            { internalType: "address", name: "collection", type: "address" },
          ],
          internalType: "struct GameManager.TransferNftData",
          name: "transferNftData",
          type: "tuple",
        },
      ],
      name: "getTransferNftDataHash",
      outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      stateMutability: "pure",
      type: "function",
    },
    {
      inputs: [
        { internalType: "bytes32", name: "role", type: "bytes32" },
        { internalType: "address", name: "account", type: "address" },
      ],
      name: "grantRole",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "bytes32", name: "role", type: "bytes32" },
        { internalType: "address", name: "account", type: "address" },
      ],
      name: "hasRole",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          components: [
            { internalType: "uint256", name: "id", type: "uint256" },
            { internalType: "string", name: "uri", type: "string" },
            { internalType: "address", name: "NFTContract", type: "address" },
          ],
          internalType: "struct GameManager.CardPackData[]",
          name: "cardPackData",
          type: "tuple[]",
        },
        {
          components: [
            { internalType: "address", name: "token", type: "address" },
            { internalType: "uint256", name: "cost", type: "uint256" },
          ],
          internalType: "struct GameManager.CardPackCost",
          name: "cardPackCost",
          type: "tuple",
        },
        { internalType: "bytes", name: "signature", type: "bytes" },
      ],
      name: "openCardPack",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "collection", type: "address" },
      ],
      name: "removeCollection",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "bytes32", name: "role", type: "bytes32" },
        { internalType: "address", name: "account", type: "address" },
      ],
      name: "renounceRole",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "bytes32", name: "role", type: "bytes32" },
        { internalType: "address", name: "account", type: "address" },
      ],
      name: "revokeRole",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          components: [
            { internalType: "uint256", name: "amount", type: "uint256" },
            { internalType: "uint256", name: "burn", type: "uint256" },
            { internalType: "address", name: "payee", type: "address" },
            { internalType: "address", name: "token", type: "address" },
          ],
          internalType: "struct GameManager.RewardAndBurnData",
          name: "rewardAndBurnData",
          type: "tuple",
        },
        { internalType: "bytes", name: "signature", type: "bytes" },
      ],
      name: "rewardBurnAndMint",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "_admin", type: "address" }],
      name: "setAdminAddress",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
      name: "supportsInterface",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "totalFunds",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          components: [
            { internalType: "address", name: "buyer", type: "address" },
            { internalType: "address", name: "seller", type: "address" },
            { internalType: "uint256", name: "amount", type: "uint256" },
            { internalType: "uint256", name: "tokenId", type: "uint256" },
            { internalType: "address", name: "token", type: "address" },
            { internalType: "uint256", name: "platformRatio", type: "uint256" },
            { internalType: "address", name: "collection", type: "address" },
          ],
          internalType: "struct GameManager.TransferNftData",
          name: "transferNftData",
          type: "tuple",
        },
        { internalType: "bytes", name: "signature", type: "bytes" },
      ],
      name: "transferNft",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ],

  //mainnet
  //address: "0x27e2A0E643c7f17959F84C345d2123B77bbd412c",
  //testnet
  // address: "0x0415A05C7616b206e91C1eD131A17220411cf3DD"
  address: "0xA449E5824957DAe4e2aa29Cd54A926D430a7eAB2",
};
export default GameManager;
