import * as React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import RecentSalesCards from "../card/RecentSalesCards";
import TopSalesCards from "../card/TopSalesCards";
import CharactersCards from "../card/CharactersCards";

const DashboardContent = (props: any) => {
  return (
    <React.Fragment>
      <TopSalesCards />
      <RecentSalesCards />
      <CharactersCards />
    </React.Fragment>
  );
};

export default DashboardContent;
