import React from "react";
import Filters from "./Filters";
import DopezFilters from "./DopezFilters";
import DozFilters from "./DozFilters";
import ProfileDetails from "./ProfileDetails";
import Contracts from "../../../contractData/contracts";
import "@fontsource/montserrat";

export default function DynamicFilter(props: any) {
  if (props.type.toLowerCase() === Contracts.KINGDOM.toLowerCase()) {
    return (
      <>
        <Filters
          priceFilter={props.priceFilter}
          setPriceFilter={props.setPriceFilter}
          maxPrice={props.maxPrice}
          setPriceFilterCommitted={props.setPriceFilterCommitted}
        />
      </>
    );
  } else if (props.type === "Character") {
    return (
      <>
        <DopezFilters
          key1={props.key1}
          handleClassChange={props.handleClassChange}
          clas={props.clas}
          handleFactionChange={props.handleFactionChange}
          factions={props.factions}
          rarityHandleChange={props.rarityHandleChange}
          rarity={props.rarity}
          page={props.page}
          setPage={props.setPage}
          Loading={props.Loading}
          setLoading={props.setLoading}
          priceFilter={props.priceFilter}
          setPriceFilter={props.setPriceFilter}
          maxPrice={props.maxPrice}
          setPriceFilterCommitted={props.setPriceFilterCommitted}
          dopezRarity={props.dopezRarity}
          setDopezRarity={props.setDopezRarity}
          DopezClass={props.DopezClass}
          setDopezClass={props.setDopezClass}
          //level
          powerFilter={props.powerFilter}
          setPowerFilter={props.setPowerFilter}
          maxPower={props.maxPower}
          skillFilter={props.skillFilter}
          setSkillFilter={props.setSkillFilter}
          maxSkill={props.maxSkill}
          common={props.common}
          epic={props.epic}
          rare={props.rare}
          legendary={props.legendary}
          swords={props.swords}
          archer={props.archer}
          fire={props.fire}
          javelin={props.javelin}
          setCommon={props.setCommon}
          setEpic={props.setEpic}
          setRare={props.setRare}
          setLegendary={props.setLegendary}
          setSwords={props.setSwords}
          setArcher={props.setArcher}
          setFire={props.setFire}
          setJavelin={props.setJavelin}
          // class
          soldier={props.soldier}
          ranged={props.ranged}
          brute={props.brute}
          support={props.support}
          mounted={props.mounted}
          setSoldier={props.setSoldier}
          setRanged={props.setRanged}
          setBrute={props.setBrute}
          setSupport={props.setSupport}
          setMounted={props.setMounted}
          // Faction
          remus={props.remus}
          romulus={props.romulus}
          greekEmpire={props.greekEmpire}
          africanTribes={props.africanTribes}
          tbd={props.tbd}
          setRemus={props.setRemus}
          setRomulus={props.setRomulus}
          setGreekEmpire={props.setGreekEmpire}
          setAfricanTribes={props.setAfricanTribes}
          setTbd={props.setTbd}
        />
      </>
    );
  } else if (props.type.toLowerCase() === Contracts.CAMEL.toLowerCase()) {
    return (
      <>
        <DozFilters
          priceFilter={props.priceFilter}
          setPriceFilter={props.setPriceFilter}
          maxPrice={props.maxPrice}
          setPriceFilterCommitted={props.setPriceFilterCommitted}
        />
      </>
    );
  } else {
    return <></>;
  }
}
