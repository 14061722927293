import * as React from "react";
// Material
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import DividerIcon from "src/assets/antara-exports/MainMenu/Title_Icon.png";
import Avatar from "@mui/material/Avatar";
import { useHistory } from "react-router-dom";
import Chip from "@mui/material/Chip";
import DopezRarity from "./dopezRarity";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Contracts from "../../contractData/contracts";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
//Rarity
import Rare from "../../assets/antara-exports/MainMenu/Raree.png";
import Common from "../../assets/antara-exports/MainMenu/Common.png";
import Epic from "../../assets/antara-exports/MainMenu/Epic.png";
import Legendary from "../../assets/antara-exports/MainMenu/Legendary.png";
//Class
import Soldier from "../../assets/antara-exports/MainMenu/Soldier.png";
import Ranged from "../../assets/antara-exports/MainMenu/Ranged.png";
import Support from "../../assets/antara-exports/MainMenu/Support.png";
import Brute from "../../assets/antara-exports/MainMenu/Brute.png";
import Mounted from "../../assets/antara-exports/MainMenu/Mounted.png";
//Faction
import Romulus from "../../assets/antara-exports/MainMenu/Romulus.png";
import Remus from "../../assets/antara-exports/MainMenu/Remus.png";
import Greek from "../../assets/antara-exports/MainMenu/Greek.png";
import AfricanTribe from "../../assets/antara-exports/MainMenu/AfricanTribe.png";
//VEMP
import Vemp from "../../assets/antara-exports/MainMenu/Vemp.png";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import "@fontsource/montserrat";
import { Image as MImage } from "mui-image";
import Skeleton from "@mui/material/Skeleton";
import { useGetVempPrice } from "../hooks/vempPrice";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#541b20",
    color: "rgba(0, 0, 0, 0.87)",
    width: 180,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #a26f74",
    borderRadius: 0,
  },
}));

const factionIcons = [
  {
    name: "Romulus",
    icon: Romulus,
  },
  { name: "Remus", icon: Remus },
  {
    name: "GreekEmpire",
    icon: Greek,
  },
  { name: "AfricanTribe", icon: AfricanTribe },
  { name: "TBD", icon: Common },
];

const classIcons = [
  {
    name: "Soldier",
    icon: Soldier,
  },
  { name: "Ranged", icon: Ranged },
  {
    name: "Brute",
    icon: Brute,
  },
  { name: "Support", icon: Support },
  { name: "Mounted", icon: Mounted },
];

const rarityIcons = [
  {
    name: "Common",
    icon: Common,
  },
  { name: "Rare", icon: Rare },
  {
    name: "Epic",
    icon: Epic,
  },
  { name: "Legendary", icon: Legendary },
];

const useStyles = makeStyles({
  ellipsis: {
    maxWidth: 100, // percentage also works
    // whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const BlockzCard = (props: any) => {
  const classes = useStyles();
  let history = useHistory();
  //const price = useGetVempPrice();
  const [usdPrice, setUsdPrice] = React.useState<number | null>(null);
  const [imageLoaded, setImageLoaded] = React.useState<boolean>(false);

  const getFactionIcon = (item: any) => {
    const icon = factionIcons.find((fact: any) => fact.name === item)?.icon;
    return icon;
  };

  const getClassIcon = (item: any) => {
    const icon = classIcons.find((fact: any) => fact.name === item)?.icon;
    return icon;
  };

  const getRarityIcon = (item: any) => {
    const icon = rarityIcons.find((fact: any) => fact.name === item)?.icon;
    return icon;
  };

  React.useEffect(() => {
    let dollarPrice: number;
    if (localStorage.getItem("vempPrice")) {
      dollarPrice = Number(localStorage.getItem("vempPrice")) * props?.price;
      setUsdPrice(dollarPrice);
    } else {
      axios
        .get(
          "https://api.coingecko.com/api/v3/simple/price?ids=vempire-ddao&vs_currencies=usd"
        )
        .then(function (response: any) {
          console.log("USD: ", response);
          let dollarPrice: number =
            response?.data["vempire-ddao"]?.usd * props?.price;
          localStorage.setItem(
            "vempPrice",
            response?.data["vempire-ddao"]?.usd
          );
          setUsdPrice(dollarPrice);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    //dollarPrice = price * props?.price;
    //setUsdPrice(dollarPrice);
  }, [props.price]);

  React.useEffect(() => {
    const img: any = new Image();
    img.src = props.nft.image;
    img.onload = () => {
      setImageLoaded(true);
    };
  }, [props?.nft?.image]);

  function handleClick() {
    if (props.type.toLowerCase() === Contracts.KINGDOM.toLowerCase()) {
      history.push({
        pathname: "/kingdoms/" + props.nft._id,
        state: { card: props, type: props.type },
      });
    } else if (props.type === "Character") {
      history.push({
        pathname: "/nft/" + props.nft.tokenId,
        state: { card: props, type: props.type },
      });
    } else if (props.type.toLowerCase() === Contracts.CAMEL.toLowerCase()) {
      history.push({
        pathname: "/camels/" + props.nft._id,
        state: { card: props, type: props.type },
      });
    }
  }

  let priceNft;

  if (props.status === "listed") {
    priceNft = (
      <>
        <Grid item sx={{ display: "flex" }}>
          <img
            src={Vemp}
            width="18px"
            height="16px"
            style={{ marginTop: 2 }}
          ></img>
          <Typography
            sx={{
              color: "#ffffff",
              fontFamily: "Montserrat, medium, sans-serif",
              fontSize: "14px",
              opacity: 0.75,
              "& .MuiTypography-root": {
                color: "#ffffff",
                fontFamily: "Montserrat, medium, sans-serif",
              },
            }}
          >
            {props.price.toPrecision(4)} VEMP
          </Typography>
        </Grid>
        <Grid>
          <Typography
            className={classes.ellipsis}
            sx={{
              fontFamily: "Montserrat, medium, sans-serif",
              color: "#ffffff",
              fontSize: "15px",
              "& .MuiTypography-root": {
                color: "#ffffff",
                fontFamily: "Montserrat, medium, sans-serif",
              },
            }}
          >
            ({usdPrice ? "$" + usdPrice?.toPrecision(3) : ""})
          </Typography>
        </Grid>
      </>
    );
  } else {
    priceNft = (
      <Typography sx={{ fontFamily: "Montserrat, medium, sans-serif" }}>
        {props.status}
      </Typography>
    );
  }

  return (
    <Container>
      <div className="nft-container card-corners">
        <Card
          className="nft-card card-corners"
          onClick={handleClick}
          sx={{ borderRadius: "0 !important" }}
        >
          <CardActionArea>
            <CardContent sx={{ height: "334px", p: 0 }}>
              {/* width: "252px", p: 0 }} */}
              <Typography
                variant="h6"
                sx={{
                  backgroundImage:
                    "linear-gradient(180deg, rgb(228,218,216) 0%, rgb(228,218,216) 30%, rgb(182,166,160) 50%)",
                  WebkitBackgroundClip: "text",
                  fontFamily: "Montserrat, medium, sans-serif",
                  textShadow: "none",
                  p: 2,
                  pb: 0 /* 
                  borderBottom: "#f4cd65 2px solid",
                  mx: 1,
                  mb: 2, */,
                }}
              ></Typography>
              <Box
                component="div"
                sx={{
                  ml: 1,
                  mr: 1,
                  display: "flex",
                  overflow: "hidden",
                  justifyContent: "center",
                  alignItems: "center",
                  my: 0.5,
                }}
              >
                {props.type == "Character" ? (
                  imageLoaded ? (
                    <MImage
                      src={props.nft.image}
                      errorIcon={null}
                      duration={200}
                      // className="centered-image"
                      style={{
                        flex: "none",
                        // width: "200px",
                        height: "214px",
                      }}
                      onLoad={() => {
                        console.log("image on load called");
                        setImageLoaded(true);
                      }}
                    ></MImage>
                  ) : (
                    <Skeleton variant="rectangular" width={210} height={214} />
                  )
                ) : (
                  ""
                )}

                {props.type.toLowerCase() == Contracts.KINGDOM.toLowerCase() ? (
                  <img
                    src={props.nft.image}
                    style={{
                      flex: "none",
                      width: "272.4px",
                      height: "188px",
                      paddingBottom: "20px",
                      paddingTop: "20px",
                    }}
                  ></img>
                ) : (
                  ""
                )}

                {props.type.toLowerCase() == Contracts.CAMEL.toLowerCase() ? (
                  <img
                    src={props.nft.image}
                    style={{
                      flex: "none",
                      width: "90%",
                      height: "90%",
                    }}
                  ></img>
                ) : (
                  ""
                )}
              </Box>

              <Divider sx={{ borderColor: "#f4cd65", borderWidth: "1px" }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  // py: 0.5,
                }}
              >
                <Typography
                  noWrap
                  sx={{
                    // width: "100px",
                    fontSize: "16px",
                    color: "white !important",
                    // textOverflow: "hidden",
                    px: 1,
                    py: 0.5,
                    fontFamily: "Montserrat, medium, sans-serif",
                  }}
                >
                  {props?.nft?.name}
                </Typography>

                <HtmlTooltip
                  placement="top-start"
                  title={
                    <>
                      <Typography
                        color="#ffeaf2"
                        align="center"
                        sx={{
                          fontSize: "18px",
                          fontFamily: "Montserrat, medium, sans-serif",
                        }}
                      >
                        Character Stats
                      </Typography>
                      <Grid container>
                        <Grid item pr={1}>
                          <img
                            src={getFactionIcon(props?.nft?.metadata?.faction)}
                            width="35px"
                          ></img>
                        </Grid>
                        <Grid
                          item
                          md={8}
                          sm={12}
                          xs={12}
                          sx={{ overflow: "hidden" }}
                        >
                          <Typography
                            align="left"
                            sx={{
                              pt: 1,
                              fontSize: "15px",
                              color: "#ffffff",
                              fontFamily: "Montserrat, medium, sans-serif",
                            }}
                          >
                            {props?.nft?.metadata?.faction}
                          </Typography>
                        </Grid>
                        <Grid item pr={1}>
                          <img
                            src={getClassIcon(props?.nft?.metadata?.class)}
                            width="35px"
                          ></img>
                        </Grid>
                        <Grid
                          item
                          md={8}
                          sm={12}
                          xs={12}
                          sx={{ overflow: "hidden" }}
                        >
                          <Typography
                            color="#ffffff"
                            sx={{
                              pt: 1,
                              fontFamily: "Montserrat, medium, sans-serif",
                              fontSize: "15px",
                            }}
                          >
                            {props?.nft?.metadata?.class}
                          </Typography>
                        </Grid>
                        <Grid item pr={1}>
                          <img
                            src={getRarityIcon(props?.nft?.metadata?.rarity)}
                            width="35px"
                          ></img>
                        </Grid>
                        <Grid
                          item
                          md={8}
                          sm={12}
                          xs={12}
                          sx={{ overflow: "hidden" }}
                        >
                          <Typography
                            color="#ffffff"
                            sx={{
                              pt: 1,
                              fontFamily: "Montserrat, medium, sans-serif",
                              fontSize: "15px",
                            }}
                          >
                            {props?.nft?.metadata?.rarity}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  }
                >
                  <IconButton>
                    <InfoOutlinedIcon sx={{ color: "white", pb: 0.5 }} />
                  </IconButton>
                </HtmlTooltip>
              </Box>

              <Grid
                container
                sx={{
                  p: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {priceNft}
              </Grid>
              {/* </Box> */}
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </Container>
  );
};

export type ClaimCardProps = {
  title: string;
  number: number;
  price: number;
  actionName: string;
  action: () => void;
};

export default BlockzCard;
