import * as React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useAuth } from "../hooks/web3Auth";
import leftDivider from "src/assets/antara-exports/MainMenu/left.png";
import rightDivider from "src/assets/antara-exports/MainMenu/right.png";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import TableSkeleton from "./Skeletons/TableSkeleton";
import "@fontsource/montserrat";

interface Column {
  id:
    | "Name"
    | "TokenID"
    | "PurchaseDate"
    | "Price"
    | "PreviousOwner"
    | "TransactionType";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "Name", label: "Name", minWidth: 100 },
  { id: "TokenID", label: "Token ID", minWidth: 100 },
  { id: "PurchaseDate", label: "Purchase Date", minWidth: 100 },
  {
    id: "Price",
    label: "Price",
    minWidth: 100,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "PreviousOwner",
    label: "Previous Owner",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "TransactionType",
    label: "Transaction Type",
    minWidth: 170,
    align: "right",
  },
];

interface Data {
  Name: string;
  TokenID: string;
  PurchaseDate: string;
  Price: number;
  PreviousOwner: string;
  TransactionType: string;
}

function createData(
  Name: string,
  TokenID: string,
  PurchaseDate: string,
  Price: number,
  seller: string,
  buyer: string,
  account: any
): Data {
  let PreviousOwner = "";
  let TransactionType = "";
  if (account === seller) {
    PreviousOwner = account;
    TransactionType = "Sold";
  } else {
    PreviousOwner = seller;
    TransactionType = "Bought";
  }
  return { Name, TokenID, PurchaseDate, Price, PreviousOwner, TransactionType };
}

const defaultRows: any = [];

export default function AccountHistory() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [offset, setOffset] = React.useState(0);
  const [count, setCount] = React.useState(0);

  const [rows, setRows] = React.useState([]);
  const { account } = useAuth();
  const [Loading, setLoading] = React.useState(false);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    // setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    // setOffset(rowsPerPage * page);
  };

  React.useEffect(() => {
    const offset = page * rowsPerPage;
    let url: string;
    url = `/api/transactions/wallet/${offset}/${rowsPerPage}`;
    let payload = {
      wallet: account,
    };
    axios
      .post(url, payload)
      .then(function (response: any) {
        console.log("Account history: ", response);
        let data: any = [];
        setCount(response.data.transactions.count);
        response.data.transactions.data.map((tran: any) => {
          return data.push(
            createData(
              tran?.nft?.name,
              tran.nft?.tokenId?.toString(),
              new Date(tran.createdAt).toDateString(),
              tran.price,
              tran.seller,
              tran.buyer,
              account
            )
          );
        });
        setRows(data);
        setLoading(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [account, page, rowsPerPage]);

  return (
    <>
      <div className="pageMaxSize">
        <Box
          // direction="row"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pt: 4,
          }}
        >
          <img src={leftDivider} width="60px" style={{ marginTop: 20 }}></img>
          <Typography
            sx={{
              color: "#ffffff",
              fontSize: "22px",
              px: 1,
              fontFamily: "Montserrat, medium, sans-serif",
            }}
          >
            ACCOUNT HISTORY
          </Typography>
          <img src={rightDivider} width="60px" style={{ marginTop: 20 }}></img>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Divider
            sx={{
              mb: 4,
              width: "210px",
              borderColor: "#ffffff !important",
              mt: -0.5,
            }}
          />
        </Box>

        <Card
          sx={{
            width: "80%",
            overflow: "hidden",
            ml: "10%",
            mr: "10%",
            border: "1px solid #ffffff",
            borderRadius: "0px",
            mt: 1,
            backgroundColor: "transparent !important",
            textAlign: "center",
          }}
        >
          {Loading ? (
            <TableContainer sx={{ cursor: "default" }}>
              <Table
                stickyHeader
                sx={{
                  ".MuiTableCell-root": {
                    borderBottom: "1px solid #4c2c20",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        sx={{
                          fontFamily: "Montserrat, medium, sans-serif",
                          minWidth: column.minWidth,
                          backgroundColor: "transparent",
                          borderColor: "#4c2c20",
                          color: "#ffffff",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.TokenID}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                sx={{
                                  color: "#ffffff",
                                  fontFamily: "Montserrat, medium, sans-serif",
                                }}
                                key={column.id}
                              >
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <TablePagination
                sx={{
                  color: "#ffffff",
                  "& .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon": {
                    color: "#ffffff",
                  },
                  "& .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled":
                    { color: "#ffffff" },
                  "& .MuiTablePagination-selectLabel": {
                    fontFamily: "Montserrat, medium, sans-serif",
                  },
                  "& .MuiTablePagination-displayedRows": {
                    fontFamily: "Montserrat, medium, sans-serif",
                  },
                  "& .MuiInputBase-input.MuiSelect-select": {
                    fontFamily: "Montserrat, medium, sans-serif",
                  },
                  "& .MuiMenuItem-root-MuiTablePagination-menuItem": {
                    fontFamily: "Montserrat, medium, sans-serif",
                  },
                  "& .MuiMenuItemRoot": {
                    fontFamily: "Montserrat, medium, sans-serif !important",
                  },
                  "& .MuiTablePagination-menuItem": {
                    fontFamily: "Montserrat, medium, sans-serif !important",
                  },
                }}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{
                  MenuProps: {
                    sx: {
                      "& .MuiPaper-root": {
                        backgroundColor: "#2e060d",
                        color: "#ffffff",
                        fontFamily: "Montserrat, medium, sans-serif !important",
                      },
                      "& .css-hgavz8-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem":
                        {
                          fontFamily:
                            "Montserrat, medium, sans-serif !important",
                        },
                      "& .MuiMenuItemRoot": {
                        fontFamily: "Montserrat, medium, sans-serif !important",
                      },
                      "& .MuiTablePagination-menuItem": {
                        fontFamily: "Montserrat, medium, sans-serif !important",
                      },
                    },
                  },
                }}
              />
            </TableContainer>
          ) : (
            <TableSkeleton />
          )}
        </Card>
      </div>
    </>
  );
}
