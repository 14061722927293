import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/material";

export default function CardsSkeleton() {
  return (
    <Container>
      <Grid
        container
        spacing={8}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 12,
          py: 12,
        }}
      >
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Skeleton variant="rectangular" width={220} height={330} />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Skeleton variant="rectangular" width={220} height={330} />
        </Grid>

        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Skeleton variant="rectangular" width={220} height={330} />
        </Grid>

        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Skeleton variant="rectangular" width={220} height={330} />
        </Grid>

        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Skeleton variant="rectangular" width={220} height={330} />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Skeleton variant="rectangular" width={220} height={330} />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Skeleton variant="rectangular" width={220} height={330} />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Skeleton variant="rectangular" width={220} height={330} />
        </Grid>
      </Grid>
    </Container>
  );
}
