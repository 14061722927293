import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MarketplaceContent from "./marketplaceContent";
import { useLocation } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Blackmarket() {
  const [value, setValue] = React.useState(1);
  const location: any = useLocation();
  const [type] = React.useState(location.state?.type);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log(event);
    setValue(newValue);
  };
  React.useEffect(() => {
    console.log("type in blackmarket is: " + type);
    if (type === "Kingdoms") {
      setValue(1);
    } else if (type === "Chiefs") {
      setValue(2);
    } else if (type === "Camels") {
      setValue(3);
    }
  }, [type]);
  var sectionStyle = {
    marginTop: 0,
    color: "primary",
  };
  return (
    <React.Fragment>
      <CssBaseline />

      <div style={sectionStyle} className="pageMaxSize">
        <TabPanel value={value} index={1}>
          <MarketplaceContent type="Character" path="market" />
        </TabPanel>
      </div>
    </React.Fragment>
  );
}
