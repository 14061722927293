// Material
import Fab, { FabProps } from "@mui/material/Fab";
import { styled } from "@mui/system";

type FabBasicProps = {
  width?: string | number;
  href?: string;
  target?: string;
  background?: "default" | "primary" | "secondary";
  solidDisabledText?: boolean;
} & FabProps;

const BasicButton = styled(Fab, {
  shouldForwardProp: (prop) =>
    prop !== "width" && prop !== "background" && prop !== "solidDisabledText",
})<FabBasicProps>(({ theme, width, color, background, solidDisabledText }) => ({
  width: "162px" || undefined,
  borderColor: theme.palette[color || "primary"].main,
  /* background:
    (background === "primary" && theme.palette.primary.dark) ||
    (background === "secondary" && theme.palette.secondary.dark) ||
    "transparent", */
  background: "transparent", //"radial-gradient(circle,rgba(154, 44, 37, 1) 0%,rgba(101, 38, 33, 1) 100%)",
  borderStyle: "solid",
  borderRadius: "0px",
  marginRight: "10px",
  marginTop: "3px",
  height: "40px",
  // clipPath: "polygon(5% 0,95% 0,100% 20%,100% 80%,95% 100%,5% 100%,0% 80%,0% 20%)",
  // boxShadow: `inset 0 0 10px ${
  //   (color == "secondary" && "rgba(29,233,182,0.65)") ||
  //   theme.palette.shadow?.primary.main
  // }`,
  color: "#ffffff",
  fontSize: "18px",
  "&:hover": {
    color: color ? theme.palette.common.black : theme.palette.grey[200],
    background:
      ((background === "primary" || color === "primary") &&
        theme.palette.primary.light) ||
      ((background === "secondary" || color === "secondary") &&
        theme.palette.secondary.light) ||
      "transparent",
  },
  "&.Mui-disabled": {
    backgroundColor: "transparent !important",
    color: "white",
    boxShadow: `inset 0 0 10px ${
      theme.palette[color || "primary"].dark
    } !important`,
    borderColor: theme.palette[color || "primary"].dark,
  },
  "&.MuiFab-sizeSmall": {
    width:
      (width &&
        (typeof width == "string" ? width : `${width}px`) + " !important") ||
      null,
  },
  "&.MuiFab-extended": {
    width:
      (width &&
        (typeof width == "string" ? width : `${width}px`) + " !important") ||
      null,
  },
}));

export default BasicButton;
