import React from "react";
import Contracts from "../../contractData/contracts";

export default function NftImg(props: any) {
  let styles;
  console.log("nft image props type is ", props.type);
  styles = {
    width: "100%",
    height: "400px",
    // paddingTop: "20px",
  };
  /* if (props.type === "Character") {
    styles = {
      width: "100%",
      height: "400px",
      // paddingTop: "20px",
    };
  } else if (props.type?.toLowerCase() === Contracts.KINGDOM.toLowerCase()) {
    styles = {
      height: "450px",
      width: "512px",
      // paddingTop: "20px",
    };
  } else if (props.type?.toLowerCase() === Contracts.CAMEL.toLowerCase()) {
    styles = {
      height: "450px",
      width: "512px",
      // paddingTop: "20px",
    }; 
  }*/
  return <img src={props.img} style={styles}></img>;
}
