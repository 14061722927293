import * as React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Archer from "../../assets/antara-exports/MainMenu/Archer.png";
import Blackmarket from "../blackmarket/blackmarket";
import { useHistory } from "react-router-dom";

const CharactersCards = (props: any) => {
  let history = useHistory();

  function handleClick() {
    history.push({
      pathname: "/blackmarket",
      // state: { card: props, type: props.type },
    });
  }

  return (
    <React.Fragment>
      <Container>
        <Grid container>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              pt: 3,
            }}
          >
            <Typography
              align="left"
              sx={{
                fontSize: "17px",
                color: "#ffeaf2",
                ml: 6,
                fontFamily: "Montserrat, medium, sans-serif",
              }}
            >
              Browse By Category
            </Typography>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            sm={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pb: 1,
              ml: 2,
            }}
          >
            <Divider sx={{ borderColor: "#a26f74", width: "94%" }} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid
            item
            md={3}
            sm={12}
            xs={12}
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="nft-container card-corners">
              <Card
                className="nft-card card-corners"
                onClick={handleClick}
                sx={{ borderRadius: "0 !important" }}
              >
                <CardActionArea>
                  <CardContent sx={{ height: "334px", p: 0 }}>
                    <Grid item md={12} sm={12} xs={12}>
                      <Box
                        component="div"
                        sx={{
                          ml: 1,
                          mr: 1,
                          display: "flex",
                          overflow: "hidden",
                          justifyContent: "center",
                          alignItems: "center",
                          my: 0.5,
                        }}
                      >
                        <img
                          src={Archer}
                          style={{
                            flex: "none",
                            width: "250px",
                            height: "250px",
                          }}
                        ></img>
                      </Box>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default CharactersCards;
