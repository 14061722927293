import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import {
  InjectedConnector,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import { useEffect, useState } from "react";
import Web3 from "web3";
export const validChains = [56, 97]; // BSC Mainnet and Testnet
export const useAuth = () => {
  const { activate, deactivate, account, chainId, library } = useWeb3React();
  const switchNetwork = async () => {
    if (chainId && validChains.indexOf(chainId) < 0) {
      console.log("invalid chain");
      const connector = new InjectedConnector({});
      const provider = await connector.getProvider();
      try {
        provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: web3.utils.toHex(validChains[0]) }],
        });
      } catch (switchError: any) {
        // 4902 error code indicates the chain is missing on the wallet
        console.log(switchError);
        if (switchError.code === 4902) {
          try {
            provider.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: web3.utils.toHex(validChains[0]),
                  rpcUrls: ["https://polygon-rpc.com/"],
                  chainName: "Polygon Mainnet",
                  nativeCurrency: {
                    name: "MATIC",
                    decimals: 18,
                    symbol: "MATIC",
                  },
                  blockExplorerUrls: ["https://bscscan.com/"],
                },
              ],
            });
          } catch (error) {
            console.error(error);
          }
        }
      }
    }
  };

  const login = useCallback(async () => {
    if (chainId) console.log(validChains.indexOf(chainId));

    const connector = new InjectedConnector({});
    if (connector)
      activate(connector, async (error) => {
        console.log("failed activation", error);
        if (error)
          setTimeout(
            () => window.localStorage.setItem("connectorId", ""),
            1000
          );
      }).then(() => window.localStorage.setItem("connectorId", "injected"));
    switchNetwork();
  }, [activate]);

  const logout = useCallback(() => {
    deactivate();
    window.localStorage.setItem("connectorId", "");
  }, [deactivate]);

  return {
    login,
    logout,
    account,
    chainId,
    switchNetwork,
    validChains,
    library,
  };
};
// Automatically try to login if use has previously logged in
export const useEagerConnect = () => {
  const { login, account, switchNetwork, chainId } = useAuth();

  useEffect(() => {
    const connector = window.localStorage.getItem("connectorId");
    if (!connector) return;
    login();
  }, [login]);

  useEffect(() => {
    if (account && chainId && validChains.indexOf(chainId) < 0) {
      switchNetwork();
    }
  }, [chainId]);
};

const web3 = new Web3(Web3.givenProvider);
// easy import of a contract to interact with
export function useContract(abi: any, address: string): ContractHandles {
  const { chainId } = useWeb3React();
  const [contract, setContract] = useState(() =>
    validChains.indexOf(chainId || 0) > -1 && address
      ? new web3.eth.Contract(abi, address)
      : null
  );

  useEffect(() => {
    chainId &&
      setContract(() =>
        validChains.indexOf(chainId || 0) > -1 && address
          ? new web3.eth.Contract(abi, address)
          : null
      );
  }, [chainId, abi, address]);
  console.log("contract?.methods");
  console.log(contract?.methods);
  return { contract, methods: contract?.methods || null, web3 };
}

type ContractHandles = {
  contract: any;
  methods: any | null;
  web3: Web3;
};
