import React from "react";
import { useAuth } from "../hooks/web3Auth";
import LoginButton from "../buttons/loginButton";
import UserMenu from "./userMenu";

export default function DynamicMenu(props: any) {
  const { login, logout, account, chainId } = useAuth();
  if (account) {
    return <UserMenu settings={props.settings} />;
  } else {
    return <LoginButton chainId={chainId}/>;
  }
}
