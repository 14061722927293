import BlockzCard from "./BlockzCard";
import ComingSoon from "../blackmarket/ComingSoon";
import Contracts from "../../contractData/contracts";

export default function DynamicCard(props: any) {
  if (props.type == "Character") {
    return (
      <BlockzCard
        {...props.card}
        type={props.type}
        imageLoading={props.imageLoading}
      />
    );
  } else if (props.type.toLowerCase() == Contracts.KINGDOM.toLowerCase()) {
    return (
      <BlockzCard
        {...props.card}
        type={props.type}
        imageLoading={props.imageLoading}
      />
    );
  } else if (props.type.toLowerCase() == Contracts.CAMEL.toLowerCase()) {
    return (
      <BlockzCard
        {...props.card}
        type={props.card.Nft.collectionId._id}
        imageLoading={props.imageLoading}
      />
    );
  } else if (props.key1 == "nftByCollection") {
    return (
      <BlockzCard
        {...props.card}
        // path={props.path}
        key1={props.key1}
        imageLoading={props.imageLoading}
      />
    );
  } else {
    return <ComingSoon />;
  }
}
