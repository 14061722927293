import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useAuth } from "../hooks/web3Auth";
import { makeStyles } from "@mui/styles";
import "@fontsource/montserrat";

const useStyles = makeStyles({
  borderedCell: {
    border: "1px solid transparent",
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "130px",
  },
});

interface Column {
  id: "Price" | "From" | "To" | "Date";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  {
    id: "Price",
    label: "Price",
    minWidth: 50,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "From",
    label: "From",
    minWidth: 50,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "To",
    label: "To",
    minWidth: 100,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "Date",
    label: "Date",
    minWidth: 100,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
  },
];

interface Data {
  Price: number;
  From: string;
  To: string;
  Date: string;
}

function createData(
  Price: number,
  From: string,
  To: string,
  Date: string
): Data {
  return { Price, From, To, Date };
}

const defaultRows: any = [];

const StyledTableContainer = styled(TableContainer)`
  border: 1px solid #a26f74;
  width: 480px;
  height: 300px;
`;

function TransactionHistory(props: any) {
  const [rows, setRows] = React.useState(defaultRows);
  const { cards } = props;
  const { account } = useAuth();

  const classes = useStyles();

  React.useEffect(() => {
    let url: string;
    url = "/api/transactions/nft";
    let payload = {
      tokenId: cards?.nft?._id,
    };
    axios
      .post(url, payload)
      .then(function (response: any) {
        console.log("RESPONSE", response);
        let data: any = [];
        response.data.transactions.map((tran: any) => {
          return data.push(
            createData(
              tran.price,
              tran.seller,
              tran.buyer,
              new Date(tran.createdAt).toDateString()
            )
          );
        });
        setRows(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [account]);

  return (
    <StyledTableContainer>
      <Table
        sx={{
          ".MuiTableCell-root": {
            borderBottom: "1px solid #4c2c20",
          },
        }}
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                sx={{
                  fontFamily: "Montserrat, medium, sans-serif",
                  minWidth: column.minWidth,
                  backgroundColor: "transparent",
                  borderColor: "#4c2c20",
                  color: "#a26f74",
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 ? (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Typography
                  align="center"
                  variant="body1"
                  sx={{
                    fontFamily: "Montserrat, medium, sans-serif",
                    color: "#ffffff",
                  }}
                >
                  No Transaction History
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            rows.map((row: any) => {
              return (
                <TableRow>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        className={classes.ellipsis}
                        sx={{
                          color: "#ffffff",
                          fontFamily: "Montserrat, medium, sans-serif",
                          fontSize: "11px",
                        }}
                        key={column.id}
                      >
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}

export default TransactionHistory;
