import React from "react";
import { useAuth } from "../hooks/web3Auth";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export default function LoginButton(props: any) {
  const { login, chainId, switchNetwork, validChains, library } = useAuth();

  return (
    <>
      <Tooltip title="Metamask Extension Required">
        <Box
          className="btn-border"
          sx={{
            marginTop: "10px",
            marginRight: "10px",
          }}
        >
          <Box
            className="btn-inner"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => login()}
              // disabled
              sx={{
                border: "none",
                borderRadius: 0,
                height: "45px",
                width: "162px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "500",
                  color: "#ffffff",
                  fontFamily: "Playfair Display SC, serif",
                }}
              >
                Login
              </Typography>
            </Button>
          </Box>
        </Box>
      </Tooltip>
    </>
  );
}
