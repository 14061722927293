import * as React from "react";
import { useState, useMemo, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import PaginationItem from "@mui/material/PaginationItem";
import FormRow from "../blackmarket/Filters/Filters";
import DopezFilters from "./Filters/DopezFilters";
import DynamicCard from "../card/dynamicCard";
import axios, { CancelTokenSource } from "axios";
import DynamicFilter from "./Filters/DynamicFilter";
import {
  Button,
  Container,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
// import Button from "@mui/material/Button";
import CardsSkeleton from "./Skeletons/CardsSkeleton";
import Contracts from "../../contractData/contracts";
import leftDivider from "src/assets/antara-exports/MainMenu/left.png";
import rightDivider from "src/assets/antara-exports/MainMenu/right.png";
import "@fontsource/montserrat";
import { useAuth } from "../hooks/web3Auth";
import VempStudios from "../../assets/antara-exports/VempStudios.png";
import { useHistory, useParams } from "react-router-dom";
import { debounce } from "lodash";
import Search from "src/assets/antara-exports/MainMenu/Search.png";
import highToLow from "src/assets/antara-exports/MainMenu/filter.png";
import { makeStyles } from "@mui/styles";
import throttle from "lodash/throttle";
import useTheme from "@mui/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

// const theme = Theme();

const useStyles = makeStyles((theme) => ({
  centerAdornment: {
    marginLeft: "50%", // or your relevant measure
  },
  centerText: {
    textAlign: "center",
  },
  // gridItem: {
  //   marginLeft: -10,
  //   [theme.breakpoints.down("sm")]: {
  //     marginLeft: 0,
  //   },
  // },
}));

interface Props {
  extendedField: string;
}

export default function MarketplaceContent(props: any) {
  const classes = useStyles();
  // const theme = useTheme();

  const isMobile = useMediaQuery("(max-width:600px)");
  const ipadMini = useMediaQuery("(max-width:768px)");
  const ipadAir = useMediaQuery("(max-width:820px)");

  const { account } = useAuth();
  let historyBrowser = useHistory();

  type PageParams = {
    page: any;
  };
  let params = { page: 0 };

  const [cardsFromApi, setCardsFromApi] = useState([]);
  const [cards, setCards] = useState([]);

  //state for price filter
  const [maxPrice, setMaxPrice] = useState(1000);
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [priceFilter, setPriceFilter] = useState([0, 1000]);
  const [priceFilterC, setPriceFilterC] = useState([0, 0]);
  const [initiaLoad, setInitiaLoad] = useState(false);

  //states for dopez stats
  //state for power (level) ChiefLevel: 1, 100
  const [maxPower, setMaxPower] = useState(100);
  const [powerFilter, setPowerFilter] = useState([1, 100]);
  //state for skill ChiefSkill: 1, 100
  const [maxSkill, setMaxSkill] = useState(100);
  const [skillFilter, setSkillFilter] = useState([1, 100]);

  const [DopezClass, setDopezClass] = useState(() => [
    "Swords Man",
    "Archer",
    "Fire Thrower",
    "Javelin Thrower",
  ]);

  const [dopezRarity, setDopezRarity] = useState(() => [
    "Common",
    "Rare",
    "Epic",
    "Legendary",
  ]);

  const [rarity, setRarity] = useState(() => [
    "Common",
    "Rare",
    "Epic",
    "Legendary",
  ]);

  const [factions, setFactions] = useState(() => [
    "Remus",
    "Romulus",
    "GreekEmpire",
    "AfricanTribe",
    "TBD",
  ]);

  const [clas, setClas] = useState([
    "Soldier",
    "Ranged",
    "Brute",
    "Support",
    "Mounted",
  ]);
  const [common, setCommon] = useState(true);
  const [rare, setRare] = useState(true);
  const [epic, setEpic] = useState(true);
  const [legendary, setLegendary] = useState(true);

  const [swords, setSwords] = useState(true);
  const [archer, setArcher] = useState(true);
  const [fire, setFire] = useState(true);
  const [javelin, setJavelin] = useState(true);

  const [soldier, setSoldier] = useState(true);
  const [ranged, setRanged] = useState(true);
  const [brute, setBrute] = useState(true);
  const [support, setSupport] = useState(true);
  const [mounted, setMounted] = useState(true);

  const [remus, setRemus] = useState(true);
  const [romulus, setRomulus] = useState(true);
  const [greekEmpire, setGreekEmpire] = useState(true);
  const [africanTribes, setAfricanTribes] = useState(true);
  const [tbd, setTbd] = useState(true);

  const [value, setValue] = useState("");

  // const [searchParams, setSearchParams] = useSearchParams();

  const handlePriceChange = (price: any) => {
    setPriceTouched(true);
    setPriceFilter(price);
    setPage(1);
    historyBrowser.push({
      search: `?page=${1}`,
    });
  };
  const handlePriceChangeCommitted = (value: any) => {
    console.log("in price change Committed");
    setPriceFilterC(value);
    // setPage(1);
    // historyBrowser.push({
    //   search: `?page=${1}`,
    // });
  };

  const handleRarityChange = (
    event: React.MouseEvent<HTMLElement>,
    newGenders: string[]
  ) => {
    setDopezRarity(newGenders);
  };

  const rarityHandleChange = (event: any) => {
    let newArray = [...rarity, event.target.value];
    if (rarity.includes(event.target.value)) {
      newArray = newArray.filter((type) => type !== event.target.value);
    }
    setRarity(newArray);
    setPage(1);
    historyBrowser.push({
      search: `?page=${1}`,
    });
    console.log("Rarity", rarity);
  };

  const handleFactionChange = (event: any) => {
    let newArray = [...factions, event.target.value];
    if (factions.includes(event.target.value)) {
      newArray = newArray.filter((type) => type !== event.target.value);
    }
    setFactions(newArray);
    setPage(1);
    historyBrowser.push({
      search: `?page=${1}`,
    });
    console.log("Factions", factions);
  };

  const handleClassChange = (event: any) => {
    let newArray = [...clas, event.target.value];
    if (clas.includes(event.target.value)) {
      newArray = newArray.filter((type) => type !== event.target.value);
    }
    setClas(newArray);
    setPage(1);
    historyBrowser.push({
      search: `?page=${1}`,
    });
    console.log("Classes", clas);
  };

  const [Loading, setLoading] = React.useState(false);
  const [totalListings, setTotalListings] = useState<number>(0);
  const [totalSales, setTotalSales] = useState<number>(0);
  const [totalSum, setTotalSum] = useState<number>(0);
  const [imageLoading, setImageLoading] = useState<boolean>();
  const [priceTouched, setPriceTouched] = useState<boolean>(false);
  const [sortingPriceTouched, setSortingPriceTouched] =
    useState<boolean>(false);
  const [order, setOrder] = useState<any>("asc");

  const [page, setPage] = useState<any>(1);

  const pageSize = 16;

  useEffect(() => {
    axios.post(`/api/transactions/stats`).then((response) => {
      console.log("---Stats----", response);
      setTotalSales(response?.data?.stats?.Stats);
      setTotalSum(response?.data?.stats?.Sum);
      setTotalListings(response?.data?.stats?.totalListings);
    });
  }, [account]);

  const handleClick = () => {
    setSortingPriceTouched(true);
    if (order === "asc") {
      setOrder("desc");
    } else {
      setOrder("asc");
    }
  };

  let cancelTokenSource: CancelTokenSource | null = null;

  const fetchData = async (currentOffset: number) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Request canceled");
    }
    // Create a new cancellation token source
    cancelTokenSource = axios.CancelToken.source();
    setImageLoading(true);
    setLoading(false);
    let url: string;
    if (props.path === "market") {
      url = `/api/listForSale/get-all-type/${currentOffset}/${pageSize}?sort=price:${order}`;
      // url = `http://localhost:8008/api/listForSale/get-all-collection/${offset}/${pageSize}?sort=price:${order}`;
      let payload: any = {
        type: props.type,
        rarity: rarity,
        classes: clas,
        faction: factions,
        price: priceFilter,
        name: value,
        // sortOrder: order,
      };
      if (sortingPriceTouched) {
        payload["sortOrder"] = order;
      }
      axios
        .post(url, payload, { cancelToken: cancelTokenSource.token })

        .then(function (response: any) {
          console.log("ListedNFts: ", response);
          setLoading(true);
          setCount(response?.data?.nfts?.count);
          let cardsWithType = response.data.nfts.data.filter((crd: any) => {
            return crd.collection.type === props.type;
          });
          setCardsFromApi(cardsWithType);
          setCards(cardsWithType);
          setImageLoading(false);

          if (!initiaLoad) {
            setPriceFilter([0, response.data.nfts.maxPrice]);
            if (priceFilterC[1] === 0) {
              console.log("filter update called");
              setPriceFilterC([0, response.data.nfts.maxPrice]);
            }

            setMaxPrice(response.data.nfts.maxPrice);
            setInitiaLoad(true);
          }

          if (props.type.toLowerCase() === Contracts.KINGDOM.toLowerCase()) {
          } else if (
            props.type.toLowerCase() === Contracts.CHIEF.toLowerCase()
          ) {
          } else if (
            props.type.toLowerCase() === Contracts.CAMEL.toLowerCase()
          ) {
          }
        })
        .catch(function (error) {
          if (axios.isCancel(error)) {
            console.log(error);
          } else {
            // Handle other errors
            console.log(error);
          }
        });
    } else if (props.path == "collection") {
      url = `/api/nfts/collection/${currentOffset}/${pageSize}`;
      // url = `http://localhost:8008/api/nfts/collection/${offset}/${pageSize}`;
      let payload: any = {
        collectionId: "63a1ada4cdf9adbeac8a1800",
        rarity: rarity,
        classes: clas,
        faction: factions,
        name: value,
      };
      axios
        .post(url, payload, { cancelToken: cancelTokenSource.token })
        .then(function (response: any) {
          console.log("Collection Response: ", response);
          setLoading(true);
          let cardsWithType = response.data.nfts.data.filter((crd: any) => {
            return crd.nft.collectionId.type === props.type;
          });
          setCount(response?.data?.nfts?.count);
          setCardsFromApi(cardsWithType);
          setCards(cardsWithType);
          setImageLoading(false);
          if (!initiaLoad) {
            setMaxPrice(response.data.nfts.maxPrice);
            setInitiaLoad(true);
          }
        });
    } else {
      if (props.account) {
        url = `/api/nfts/nft-by-wallet-collection/${currentOffset}/${pageSize}`;
        // url = `http://localhost:8008/api/nfts/nft-by-wallet-collection/${offset}/${pageSize}`;
        let payload: any = {
          type: props.type,
          wallet: props.account,
          rarity: rarity,
          classes: clas,
          faction: factions,
          name: value,
          // price: priceFilter,
          // sortOrder: order,
        };
        if (priceTouched) {
          payload["price"] = priceFilter;
        }
        if (sortingPriceTouched) {
          payload["sortOrder"] = order;
          payload["price"] = priceFilter;
        }
        axios
          .post(url, payload)
          .then(function (response: any) {
            console.log("PROFILE", response);
            setCount(response?.data?.nfts?.count);
            setLoading(true);
            let cardsWithType = response.data.nfts.data.filter((crd: any) => {
              return crd.nft.collectionId.type === props.type;
            });
            setCardsFromApi(cardsWithType);
            setCards(cardsWithType);
            setImageLoading(false);
            if (!initiaLoad) {
              setPriceFilter([0, response.data.nfts.maxPrice]);
              if (priceFilterC[1] === 0) {
                console.log("filter update called");
                setPriceFilterC([0, response.data.nfts.maxPrice]);
              }

              setMaxPrice(response.data.nfts.maxPrice);
              setInitiaLoad(true);
            }

            if (
              props.type.toLowerCase() ===
              "0xd8363133F72C00415666559c3A26076C54585873".toLowerCase()
            ) {
            } else if (
              props.type.toLowerCase() ===
              "0xa66406dC40d16669a00f13C9534026AFE59d5d73".toLowerCase()
            ) {
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    var search = historyBrowser.location.search.substring(1);
    if (search) {
      params.page = parseInt(search.split("=")[search.split("=").length - 1]);
    } else {
      params.page = 1;
    }
    if (params.page === 1) {
      setPage(params.page);
      historyBrowser.push({
        search: `?page=${params.page}`,
      });
    } else {
      setPage(params.page);
    }
    fetchData((params.page - 1) * pageSize).then();
  }, [clas, factions, rarity, order, props.account, priceFilterC]);

  const totalPages = useMemo(() => {
    return Math.ceil(count / pageSize);
  }, [cards, pageSize, page]);

  let [lastInputEdit, setLastInputEdit] = useState(0);
  useEffect(() => {
    if (new Date().getTime() - lastInputEdit > 300) {
      //fetchData(0);
    } else if (lastInputEdit) {
      const interval = setInterval(() => {
        if (new Date().getTime() - lastInputEdit > 300) {
          fetchData(0);
          clearInterval(interval);
        }
      }, 100);
      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }
  }, [lastInputEdit]);

  const handleFilterValueChange = React.useCallback((event: any) => {
    const inputValue = event.target.value;
    setLastInputEdit(new Date().getTime());
    setValue(inputValue);
  }, []);

  const handlePageChange = (event: any, value: any) => {
    historyBrowser.push({
      search: `?page=${value}`,
    });
    setPage(value);
    fetchData((value - 1) * pageSize).then();
  };

  let profile = props.path === "profile";

  return (
    <>
      {/* {props.path === "collection" ? (
        <>
          <Box sx={{ mt: 3 }}></Box>
        </>
      ) : null} */}

      <Box sx={{ display: "flex" }}>
        {/* <Paper
          sx={{
            backgroundColor: "transparent",
            position: "relative",
            pt: 0,
            // pl: 2,
            // pb: 3,
            minWidth: { md: "15rem", lg: "10rem", xl: "16rem" },
            maxWidth: { md: "18rem", lg: "16rem", xl: "16rem" },
            // display: {
            //   xs: "none",
            //   md: "block",
            // },
          }}
        > */}

        <Box
          sx={{
            backgroundColor: "transparent",
            width: "240px",
            position: "relative",
            borderRight: "1px solid #a26f74",
            mt: 8,
          }}
        >
          <Grid container spacing={1}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                // alignItems: "flex-start",
              }}
            >
              <FormControl
                sx={{
                  p: 1.5,
                  width: "250px",
                }}
              >
                <TextField
                  fullWidth
                  // label="Search"
                  placeholder="Search"
                  value={value}
                  size="small"
                  onChange={handleFilterValueChange}
                  InputLabelProps={{
                    style: {
                      color: "#ffffff",
                      fontFamily: "Montserrat, medium, sans-serif",
                      fontSize: "14px",
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#ffffff",
                      fontFamily: "Montserrat, medium, sans-serif",
                      fontSize: "15px",
                    },
                    startAdornment: (
                      // <InputAdornment classes={{ positionStart: classes.centerAdornment}}>
                      <img
                        src={Search}
                        width="18px"
                        style={{
                          // marginLeft: 60,
                          marginRight: 5,
                        }}
                      />
                      // </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#a26f74",
                        borderRadius: "0px",
                      },
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#ffffff",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#ffffff",
                      },
                  }}
                >
                  <img src={Search} width="20px" style={{ marginRight: 5 }} />
                </TextField>
              </FormControl>
              {/* <Box></Box> */}
            </Box>
            <Grid container item spacing={1} md={12} xs={12} sm={12}>
              <DynamicFilter
                key1={props.key1}
                type={props.type}
                priceFilter={priceFilter}
                setPriceFilter={handlePriceChange}
                maxPrice={maxPrice}
                setPriceFilterCommitted={handlePriceChangeCommitted}
                dopezRarity={dopezRarity}
                setDopezRarity={handleRarityChange}
                powerFilter={powerFilter}
                setPowerFilter={setPowerFilter}
                maxPower={maxPower}
                skillFilter={skillFilter}
                setSkillFilter={setSkillFilter}
                maxSkill={maxSkill}
                DopezClass={DopezClass}
                setDopezClass={setDopezClass}
                common={common}
                epic={epic}
                rare={rare}
                legendary={legendary}
                swords={swords}
                archer={archer}
                fire={fire}
                javelin={javelin}
                setCommon={setCommon}
                setEpic={setEpic}
                setRare={setRare}
                setLegendary={setLegendary}
                setSwords={setSwords}
                setArcher={setArcher}
                setFire={setFire}
                setJavelin={setJavelin}
                soldier={soldier}
                ranged={ranged}
                brute={brute}
                support={support}
                mounted={mounted}
                setSoldier={setSoldier}
                setRanged={setRanged}
                setBrute={setBrute}
                setSupport={setSupport}
                setMounted={setMounted}
                // factions={factions}
                setFactions={setFactions}
                remus={remus}
                romulus={romulus}
                greekEmpire={greekEmpire}
                africanTribes={africanTribes}
                tbd={tbd}
                setRemus={setRemus}
                setRomulus={setRomulus}
                setGreekEmpire={setGreekEmpire}
                setAfricanTribes={setAfricanTribes}
                setTbd={setTbd}
                Loading={Loading}
                setLoading={setLoading}
                setPage={setPage}
                page={page}
                rarityHandleChange={rarityHandleChange}
                rarity={rarity}
                handleFactionChange={handleFactionChange}
                factions={factions}
                handleClassChange={handleClassChange}
                clas={clas}
              />
            </Grid>
          </Grid>

          <Grid
            item
            sx={{
              // display: "flex",
              mt: 20,
              justifyContent: "center",
              alignContent: "flex-end",
            }}
          >
            <Divider
              sx={{
                m: 2,
                border: "1px solid #a26f74",
                // borderWidth: "1px",
              }}
            />
          </Grid>
          <Grid item sx={{ mb: 1 }}>
            <Typography
              sx={{
                fontFamily: "Montserrat, medium, sans-serif",
                color: "#ffffff",
              }}
            >
              Powered By
            </Typography>
          </Grid>
          <Grid item>
            <a
              href="https://www.vemp.xyz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={VempStudios} width="150px"></img>
            </a>
          </Grid>
        </Box>
        {/* </Paper> */}

        <Paper
          sx={{
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
            position: "relative",
            // pt: 4,
            pb: 3,
            width: { md: "100%", xs: "100%", xl: "90%" },
            boxShadow: "none",
          }}
        >
          {Loading ? (
            <Container>
              <Grid
                container
                // className={classes.gridItem}
                spacing={0}
                sx={{
                  marginLeft: isMobile ? 0 : ipadMini ? 3 : ipadAir ? 3 : -10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // ml: -10,
                  // mt: -8,
                }}
              >
                <Box
                  className="grid"
                  sx={{
                    width: "200px",
                    m: 1,
                    // p: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item md={12} sm={4} xs={3} direction="column">
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#f9c920",
                        fontFamily: "Montserrat, medium, sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      Total Sales
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#ffeaf2",
                        fontFamily: "Montserrat, medium, sans-serif",
                        fontSize: "20px",
                      }}
                    >
                      {totalSales?.toLocaleString("en-US")}
                    </Typography>
                  </Grid>
                </Box>
                <Box
                  className="grid"
                  sx={{
                    width: "200px",
                    m: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item md={12} sm={3} xs={3} direction="column">
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#f9c920",
                        fontFamily: "Montserrat, medium, sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      Volume
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#ffeaf2",
                        fontFamily: "Montserrat, medium, sans-serif",
                        fontSize: "20px",
                      }}
                    >
                      {totalSum?.toLocaleString("en-US")}
                    </Typography>
                  </Grid>
                </Box>
                <Box
                  className="grid"
                  sx={{
                    width: "200px",
                    m: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item md={12} sm={3} xs={3} direction="column">
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#f9c920",
                        fontFamily: "Montserrat, medium, sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      Items Listed
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#ffeaf2",
                        fontFamily: "Montserrat, medium, sans-serif",
                        fontSize: "20px",
                      }}
                    >
                      {totalListings}
                    </Typography>
                  </Grid>
                </Box>
                <Box
                  className="grid"
                  sx={{
                    width: "200px",
                    m: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item md={12} sm={3} xs={3} direction="column">
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#f9c920",
                        fontFamily: "Montserrat, medium, sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      Highest VEMP Price
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#ffeaf2",
                        fontFamily: "Montserrat, medium, sans-serif",
                        fontSize: "20px",
                      }}
                    >
                      {maxPrice?.toLocaleString("en-US")}
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              {!props.key1 ? (
                <>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    lg={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      mt: isMobile ? 2 : ipadMini ? 2 : ipadAir ? 2 : 0,
                      mx: isMobile ? 3 : ipadMini ? 0 : ipadAir ? 0 : 5,
                    }}
                  >
                    <Button
                      onClick={() => {
                        handleClick();
                      }}
                      sx={{
                        textTransform: "none",
                        color: "#ffffff",
                        fontFamily: "Montserrat, medium, sans-serif",
                        border: "1px solid #a26f74",
                        borderRadius: "0px",
                        width: "220px",
                        height: "38px",
                        // my: 1.5,
                        // mx: 5,
                      }}
                    >
                      <img
                        src={highToLow}
                        width="20px"
                        style={{ marginRight: 5 }}
                      />
                      {order === "asc"
                        ? "Price High to Low"
                        : "Price Low to High"}
                    </Button>
                  </Grid>
                </>
              ) : null}

              {profile ? (
                <>
                  <Box sx={{}}>
                    <Box
                      // direction="row"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // mt: -3,
                      }}
                    >
                      <img
                        src={leftDivider}
                        width="60px"
                        style={{ marginTop: 10 }}
                      ></img>
                      <Typography
                        sx={{
                          color: "#ffffff",
                          fontSize: "22px",
                          // px: 1,
                          fontFamily: "Montserrat, medium, sans-serif",
                        }}
                      >
                        PROFILE
                      </Typography>
                      <img
                        src={rightDivider}
                        width="60px"
                        style={{ marginTop: 10 }}
                      ></img>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Divider
                        sx={{
                          width: "100px",
                          borderColor: "#ffffff !important",
                          mt: -0.5,
                        }}
                      />
                    </Box>
                  </Box>
                </>
              ) : null}

              <Grid
                container
                justifyContent="center"
                columnSpacing={{ xs: 1, sm: 7, md: -5 }}
                rowSpacing={{ xs: 2, sm: 3, md: 4 }}
                alignItems="center"
              >
                {cards.map((card, cardIdx) => (
                  <Grid
                    item
                    key={`pool-card-${cardIdx}`}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 3,
                    }}
                  >
                    <DynamicCard
                      type={props.type}
                      card={card}
                      imageLoading={imageLoading}
                      key1={props.key1}
                    />
                  </Grid>
                ))}
                {cards.length > 0 ? (
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sm={12}
                    // direction="column"
                    // justifyContent="center"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Stack spacing={2}>
                      <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        renderItem={(item) => (
                          <PaginationItem
                            sx={{
                              color: "whitesmoke",
                              fontFamily: "Montserrat, medium, sans-serif",
                            }}
                            {...item}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                ) : (
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "30vw",
                      ml: 5,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Montserrat, medium, sans-serif",
                        minWidth: "100% !important",
                        justifyContent: "center",
                        margin: "auto",
                      }}
                    >
                      No NFTs Found
                    </Typography>
                  </Grid>
                )}
                {/* <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              renderItem={(item) => (
                <PaginationItem sx={{ color: "whitesmoke" }} {...item} />
              )}
            />
          </Stack> */}
              </Grid>
            </Container>
          ) : (
            <CardsSkeleton />
          )}
        </Paper>
      </Box>
    </>
  );
}
