import React from "react";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMore from "src/assets/antara-exports/MainMenu/Down.png";
import SliderFilter from "./SliderFilter";
import Avatar from "@mui/material/Avatar";
export default function DozFilters(props: any) {
  return (
    <React.Fragment>
      <Typography
        sx={{
          color: "#f4cd65",
          marginLeft: "0 !important",
          minWidth: "100% !important",
          marginBottom: "10px",
          fontFamily: "MinionVariableConcept",
          fontSize: "24px",
          mt: 1,
        }}
      >
        Filter
      </Typography>
      <Accordion
        sx={{
          backgroundColor: "transparent",
          color: "#f4cd65",
          marginLeft: "10px !important",
          width: "98%",
          borderTop: "1px solid #4c2c20",
          borderBottom: "1px solid #4c2c20",
          borderRadius: "0 !important",
        }}
      >
        <AccordionSummary
          expandIcon={
            <Avatar
              alt="test avatar"
              src={ExpandMore}
              sx={{ height: "14px", width: "14px" }}
            />
          }
        >
          <Typography
            sx={{
              fontFamily: "MinionVariableConcept",
              fontSize: "24px",
              color: "#f4cd65",
              pl: 2,
            }}
          >
            Price
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 0 }}>
          <SliderFilter
            priceFilter={props.priceFilter}
            setPriceFilter={props.setPriceFilter}
            maxPrice={props.maxPrice}
            setPriceFilterCommitted={props.setPriceFilterCommitted}
          />
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}
