import React, { useEffect } from "react";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import { ButtonBase, Container, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import DetailCard from "../card/DetailCard";
import { makeStyles } from "@mui/styles";
import TransactionHistory from "./TransactionHistory";
import "@fontsource/montserrat";

const useStyles = makeStyles({
  ellipsis: {
    maxWidth: 150,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // display: "-webkit-box",
    // "-webkit-line-clamp": 2,
    // "-webkit-box-orient": "vertical",
  },
});

export default function DopezInfo(props: any) {
  const classes = useStyles();

  const [cards, setCards] = useState(props.cards);
  useEffect(() => {
    setCards(props.cards);
  }, [props]);
  let history = useHistory();
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  let Link = {
    name: "openSea",
    openSea: `https://testnets.opensea.io/assets/bsc-testnet/${cards?.nft?.collectionId?.contractAddress}/${cards?.nft?.tokenId}`,
  };

  console.log("tokeniddddddddddd", cards?.nft);

  return (
    <>
      <Container sx={{ padding: 3 }}>
        <DetailCard sx={{ width: "480px", p: 2 }}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{
              // width: "100%",
              // color: "#ffeaf2",
              textAlign: "left",
              p: 0.5,
            }}
          >
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              direction="row"
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Typography
                // variant="subtitle2"
                sx={{
                  color: "#a26f74",
                  fontFamily: "Montserrat, medium, sans-serif",
                  fontSize: "14px",
                }}
              >
                Name :
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Montserrat, medium, sans-serif",
                  fontSize: "15px",
                  color: "#ffeaf2",
                  px: 2,
                  // fontWeight: 10,
                }}
              >
                {cards?.nft?.name}
              </Typography>
            </Grid>

            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              direction="row"
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Typography
                // variant="subtitle2"
                sx={{
                  color: "#a26f74",
                  fontFamily: "Montserrat, medium, sans-serif",
                  fontSize: "14px",
                }}
              >
                Owned By :
              </Typography>
              <Typography
                className={classes.ellipsis}
                sx={{
                  fontFamily: "Montserrat, medium, sans-serif",
                  fontSize: "15px",
                  color: "#ffeaf2",
                  px: 2,
                }}
              >
                {cards?.nft?.ownerAddress}
              </Typography>
            </Grid>
          </Grid>
        </DetailCard>

        <DetailCard sx={{ width: "480px", p: 2 }}>
          <Grid container>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              direction="row"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "space-between",
              }}
            >
              <Typography
                // variant="subtitle2"
                sx={{
                  color: "#a26f74",
                  fontFamily: "Montserrat, medium, sans-serif",
                  fontSize: "14px",
                }}
              >
                Token Id
              </Typography>
              <Typography
                // variant="subtitle2"
                sx={{
                  color: "white",
                  fontFamily: "Montserrat, medium, sans-serif",
                  fontSize: "14px",
                }}
              >
                {cards?.nft?.tokenId}
              </Typography>
            </Grid>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              direction="row"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "space-between",
              }}
            >
              <Typography
                // variant="subtitle2"
                sx={{
                  color: "#a26f74",
                  fontFamily: "Montserrat, medium, sans-serif",
                  fontSize: "14px",
                }}
              >
                Contract Address
              </Typography>
              <Typography
                className={classes.ellipsis}
                sx={{
                  color: "white",
                  fontFamily: "Montserrat, medium, sans-serif",
                  fontSize: "14px",
                }}
              >
                {cards?.nft?.collectionId?.contractAddress}
              </Typography>
            </Grid>
            {cards?.status === "listed" ? (
              <>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  direction="row"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "space-between",
                  }}
                >
                  <Typography
                    // variant="subtitle2"
                    sx={{
                      color: "#a26f74",
                      fontFamily: "Montserrat, medium, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    View on Opensea NFT Marketplace
                  </Typography>
                  <Typography
                    // className={classes.ellipsis}
                    sx={{
                      color: "white",
                      fontFamily: "Montserrat, medium, sans-serif",
                      // fontSize: "14px",
                    }}
                  >
                    {Link.name === "openSea" ? (
                      <>
                        <ButtonBase
                          disableRipple
                          disableTouchRipple
                          sx={{ cursor: "pointer", mt: -1, ml: -3 }}
                          href={Link.openSea}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Typography
                            className={classes.ellipsis}
                            sx={{
                              color: "white",
                              fontFamily: "Montserrat, medium, sans-serif",
                              // fontSize: "14px",
                            }}
                          >
                            https://testnets.opensea.io/assets/bsc-testnet/
                          </Typography>
                        </ButtonBase>
                      </>
                    ) : (
                      <></>
                    )}
                  </Typography>
                </Grid>
              </>
            ) : null}
          </Grid>
        </DetailCard>

        <Grid item md={12} sm={12} xs={12} sx={{ pl: 1 }}>
          <TransactionHistory cards={cards} />
        </Grid>
      </Container>
      <Snackbar open={open} autoHideDuration={1500} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          sx={{ width: "100%", backgroundColor: "#d2c3a6", color: "#4c2c20" }}
        >
          Wallet Address copied to clipboard
        </Alert>
      </Snackbar>
    </>
  );
}
