import React from "react";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMore from "src/assets/antara-exports/MainMenu/Down.png";
import SliderFilter from "./SliderFilter";
import ChiefsRarity from "./ChiefsRarity";
import ChiefsClass from "./ChiefsClass";
import Classes from "./Classes";
import { Box } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Faction from "./Faction";
import "@fontsource/montserrat";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Rarity from "./Rarity";

const useStyles = makeStyles({
  root: {
    "&.custom-accordion": {
      // Apply the custom styles to the accordion
      border: "none",
      "&:before": {
        display: "none",
      },
    },
  },
});

export default function DopezFilters(props: any) {
  const classes = useStyles();
  return (
    <React.Fragment>
      {/* <Typography
        sx={{
          color: "#ffffff",
          marginLeft: "0 !important",
          minWidth: "100% !important",
          marginBottom: "10px",
          fontFamily: "MinionVariableConcept",
          fontSize: "24px",
          mt: 1,
        }}
      >
        Filter
      </Typography> */}
      {!props.key1 ? (
        <>
          {" "}
          <Accordion
            className={"borderClass"}
            sx={{
              backgroundColor: "transparent",
              color: "#ffffff",
              // marginLeft: "10px !important",
              width: "100%",
              // borderTop: "none",
              // borderBottom: "none",
              borderRadius: "0 !important",
              boxShadow: "none",
              border: "green",
              // "& .MuiAccordion-root:before": {
              //   borderBottom: "none",
              // },
              "&..css-1w7rr8s-MuiPaper-root-MuiAccordion-root:before": {
                borderBottom: "none",
              },
              "& .MuiAccordion-root.Mui-expanded:before": {
                borderBottom: "none",
              },

              "& .MuiAccordion-root:before": {
                borderBottom: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            >
              <Typography
                sx={{
                  fontFamily: "Montserrat, medium, sans-serif",
                  fontSize: "17px",
                  color: "#ffffff",
                  // pl: 2,
                }}
              >
                Price
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SliderFilter
                priceFilter={props.priceFilter}
                setPriceFilter={props.setPriceFilter}
                maxPrice={props.maxPrice}
                setPriceFilterCommitted={props.setPriceFilterCommitted}
              />
            </AccordionDetails>
          </Accordion>
        </>
      ) : null}

      <Accordion
        sx={{
          backgroundColor: "transparent",
          color: "#ffffff",
          width: "100%",
          borderRadius: "0 !important",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat, medium, sans-serif",
              fontSize: "17px",
              color: "#ffffff",
              // pl: 1,
            }}
          >
            Faction
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Faction
            page={props.page}
            setPage={props.setPage}
            factions={props.factions}
            handleFactionChange={props.handleFactionChange}
            blockzSize={props.dopezRarity}
            setBlockzSize={props.setDopezRarity}
            remus={props.remus}
            romulus={props.romulus}
            greekEmpire={props.greekEmpire}
            africanTribes={props.africanTribes}
            tbd={props.tbd}
            setRemus={props.setRemus}
            setRomulus={props.setRomulus}
            setGreekEmpire={props.setGreekEmpire}
            setAfricanTribes={props.setAfricanTribes}
            setTbd={props.setTbd}
            Loading={props.Loading}
            setLoading={props.setLoading}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          backgroundColor: "transparent",
          color: "#ffffff",
          width: "100%",
          borderRadius: "0 !important",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat, medium, sans-serif",
              fontSize: "17px",
              color: "#ffffff",
              // pl: 1,
            }}
          >
            Rarity
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ChiefsRarity
            rarity={props.rarity}
            rarityHandleChange={props.rarityHandleChange}
            blockzSize={props.dopezRarity}
            setBlockzSize={props.setDopezRarity}
            common={props.common}
            epic={props.epic}
            rare={props.rare}
            legendary={props.legendary}
            setCommon={props.setCommon}
            setEpic={props.setEpic}
            setRare={props.setRare}
            setLegendary={props.setLegendary}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          backgroundColor: "transparent",
          color: "#ffffff",
          // marginLeft: "10px !important",
          width: "100%",
          // borderTop: "1px solid #4c2c20",
          // borderBottom: "1px solid #4c2c20",
          borderRadius: "0 !important",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat, medium, sans-serif",
              fontSize: "17px",
              color: "#ffffff",
              // pl: 2,
            }}
          >
            Class
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* <ChiefsClass
            DopezClass={props.DopezClass}
            setDopezClass={props.setDopezClass}
            swords={props.swords}
            archer={props.archer}
            fire={props.fire}
            javelin={props.javelin}
            setSwords={props.setSwords}
            setArcher={props.setArcher}
            setFire={props.setFire}
            setJavelin={props.setJavelin}
            soldier={props.soldier}
            range={props.ranged}
            brute={props.brute}
            support={props.support}
            mounted={props.mounted}
            setSoldier={props.setSoldier}
            setRanged={props.setRanged}
            setBrute={props.setBrute}
            setSupport={props.setSupport}
            setMounted={props.setMounted}
          /> */}
          <Classes
            clas={props.clas}
            handleClassChange={props.handleClassChange}
            DopezClass={props.DopezClass}
            setDopezClass={props.setDopezClass}
            soldier={props.soldier}
            ranged={props.ranged}
            brute={props.brute}
            support={props.support}
            mounted={props.mounted}
            setSoldier={props.setSoldier}
            setRanged={props.setRanged}
            setBrute={props.setBrute}
            setSupport={props.setSupport}
            setMounted={props.setMounted}
          />
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}
