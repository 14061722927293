import { createTheme, responsiveFontSizes } from "@mui/material/styles";
// FONTS
import AveriaRegular from "src/assets/fonts/AveriaSerifLibre-Regular.ttf";
import AveriaBold from "src/assets/fonts/AveriaSerifLibre-Bold.ttf";
import AveriaBoldItalic from "src/assets/fonts/AveriaSerifLibre-BoldItalic.ttf";
import AveriaItalic from "src/assets/fonts/AveriaSerifLibre-Italic.ttf";
import AveriaLight from "src/assets/fonts/AveriaSerifLibre-Light.ttf";
import AveriaLightItalic from "src/assets/fonts/AveriaSerifLibre-LightItalic.ttf";
import KingThings from "src/assets/fonts/Kingthings_Petrock.ttf";
import KingThingsLight from "src/assets/fonts/Kingthings_Petrock_Light.ttf";
import PixeloidSansBold from "src/assets/fonts/PixeloidSans-Bold.ttf";
import PixeloidSans from "src/assets/fonts/PixeloidSans.ttf";
import MinionVariableConcept from "src/assets/fonts/Minion-Regular.ttf";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(244, 205, 101)",
    },
    secondary: {
      main: "rgb(24, 13, 9)",
    },
    text: {
      primary: "rgb(244, 205, 101)",
      secondary: "rgba(24, 13, 9)",
    },
    info: {
      main: "rgb(244, 205, 101)",
    },
  },

  typography: {
    fontFamily: [
      // "Montserrat, medium, sans-serif",
      "Playfair Display SC, serif",
      // "MinionVariableConcept",
      "PixeloidSans",
      "PixeloidSansBold",
      "MinionVariableConcept",
      "AveriaSerifLibre",
      "KingThings",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'PixeloidSans';
          font-style: normal;
          font-weight: normal;
          src: local('PixeloidSans'), local('PixeloidSans'), url(${PixeloidSans}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'PixeloidSansBold';
          font-style: normal;
          font-weight: normal;
          src: local('PixeloidSansBold'), local('PixeloidSansBold'), url(${PixeloidSansBold}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'MinionVariableConcept';
          font-style: normal;
          font-weight: normal;
          src: local('MinionVariableConcept'), local('MinionVariableConcept'), url(${MinionVariableConcept}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'AveriaSerifLibre';
          font-style: italic;
          font-weight: 500;
          src: local('AveriaSerifLibre'), local('AveriaSerifLibre-BoldItalic'), url(${AveriaBoldItalic}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'AveriaSerifLibre';
          font-style: italic;
          font-weight: 400;
          src: local('AveriaSerifLibre'), local('AveriaSerifLibre-Italic'), url(${AveriaItalic}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'AveriaSerifLibre';
          font-style: normal;
          font-weight: 300;
          src: local('AveriaSerifLibre'), local('AveriaSerifLibre-Light'), url(${AveriaLight}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'AveriaSerifLibre';
          font-style: italic;
          font-weight: 300;
          src: local('AveriaSerifLibre'), local('AveriaSerifLibre-LightItalic'), url(${AveriaLightItalic}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'KingThings';
          font-style: normal;
          font-weight: 400;
          src: local('KingThings'), local('Kingthings_Petrock'), url(${KingThings}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'KingThings';
          font-style: normal;
          font-weight: 300;
          src: local('KingThings'), local('Kingthings_Petrock_Light'), url(${KingThingsLight}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        
      `,
    },
  },
});

export default responsiveFontSizes(theme);
