import React, { useEffect } from "react";
import { useAuth } from "../hooks/web3Auth";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import GeneralButton from "../buttons/GeneralUseButton";
import { shortAddress } from "src/utils/text";
import "@fontsource/montserrat";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";

export default function UserMenu(props: any) {
  const history = useHistory();
  let location: any = useLocation();
  const { login, logout, account, chainId } = useAuth();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const logOut = async () => {
    handleCloseUserMenu();
    await logout();
    history.push("/");
  };
  let displayAddress = shortAddress(account || "");
  return (
    <>
      <Box
        className="btn-border"
        sx={{
          marginTop: "10px",
          marginRight: "10px",
        }}
      >
        <Box
          className="btn-inner"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Tooltip title="Open settings" sx={{ background: "transparent" }}>
            <GeneralButton
              disabled={!account}
              onClick={handleOpenUserMenu}
              solidDisabledText
              variant="extended"
            >
              {displayAddress}
            </GeneralButton>
            {/* <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, pl: 2 }}>
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton> */}
          </Tooltip>
        </Box>
      </Box>
      <Menu
        sx={{
          mt: "45px",
          color: "#ffffff",
          "& .MuiPaper-root": {
            background: "#2f070c",
            // "linear-gradient(180deg, rgba(234,223,201,1) 0%, rgba(210,195,166,1) 100%)",
            borderRadius: "0px",
            color: "#ffffff",
            border: "0.5px solid #ffffff",
            // clipPath:
            //   "polygon(5% 0,95% 0,100% 5%,100% 95%,95% 100%,5% 100%,0 95%,0 5%)",
          },
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        className="NavMenu"
      >
        {props.settings.map((setting: any, index: number) => (
          <MenuItem key={index} onClick={handleCloseUserMenu}>
            <Button href={setting.route}>
              <Typography
                textAlign="center"
                sx={{
                  color: "#ffffff",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                {setting.name}
              </Typography>
            </Button>
          </MenuItem>
        ))}
        <MenuItem key={props.settings.length} onClick={logOut}>
          <Typography
            textAlign="center"
            sx={{ p: "6px", fontFamily: "Montserrat, medium, sans-serif" }}
          >
            LOG OUT
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
