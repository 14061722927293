import React from "react";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import SquareIcon from "@mui/icons-material/Square";
import { Typography } from "@mui/material";

export default function ChiefsClass(props: any) {
  //const { "Swords Man", "Archer", "Fire Thrower", Javelin Thrower } = props.DopezClass;
  return (
    <Box sx={{ display: "flex" }}>
      <FormControl
        sx={{ my: 3, mt: 0, mb: 0 }}
        component="fieldset"
        variant="standard"
      >
        <FormGroup
          aria-label="position"
          row
          onChange={props.handleClassChange}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <FormControlLabel
            key="Soldier"
            value="Soldier"
            control={
              <Checkbox
                checked={props.clas.indexOf("Soldier") > -1}
                disableRipple
                icon={<SquareIcon fontSize="small" />}
                checkedIcon={<SquareIcon fontSize="small" />}
                // onChange={() => {
                //   props.setSoldier(!props.soldier);
                // }}
                name="Soldier"
                sx={{
                  transform: "scale(0.75)",
                  color: "#ffffff",
                  borderRadius: "0px",
                  "&.Mui-checked": {
                    color: "#a26f74",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                Soldier
              </Typography>
            }
            labelPlacement="start"
            sx={{
              ml: -0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "space-between",
              width: "220px",
              "& .Mui-checked": {
                color: "#a26f74",
              },
              "& .MuiCheckbox-colorPrimary.Mui-checked + .MuiTypography-root": {
                color: "#a26f74",
              },
            }}
          />
          <FormControlLabel
            key="Ranged"
            value="Ranged"
            control={
              <Checkbox
                checked={props.clas.indexOf("Ranged") > -1}
                disableRipple
                icon={<SquareIcon fontSize="small" />}
                checkedIcon={<SquareIcon fontSize="small" />}
                // onChange={() => {
                //   props.setRanged(!props.ranged);
                // }}
                name="Ranged"
                sx={{
                  transform: "scale(0.75)",
                  color: "#ffffff",
                  borderRadius: "0px",
                  "&.Mui-checked": {
                    color: "#a26f74",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                Ranged
              </Typography>
            }
            labelPlacement="start"
            sx={{
              ml: -0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "space-between",
              "& .Mui-checked": {
                color: "#a26f74",
              },
              width: "220px",
              "& .MuiCheckbox-colorPrimary.Mui-checked + .MuiTypography-root": {
                color: "#a26f74",
              },
            }}
          />
          <FormControlLabel
            key="Brute"
            value="Brute"
            control={
              <Checkbox
                checked={props.clas.indexOf("Brute") > -1}
                disableRipple
                icon={<SquareIcon fontSize="small" />}
                checkedIcon={<SquareIcon fontSize="small" />}
                // onChange={() => {
                //   props.setBrute(!props.brute);
                // }}
                name="Brute"
                sx={{
                  transform: "scale(0.75)",
                  color: "#ffffff",
                  "&.Mui-checked": {
                    color: "#a26f74",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                Brute
              </Typography>
            }
            labelPlacement="start"
            sx={{
              ml: -0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "space-between",
              "& .Mui-checked": {
                color: "#a26f74",
              },
              width: "220px",
              "& .MuiCheckbox-colorPrimary.Mui-checked + .MuiTypography-root": {
                color: "#a26f74",
              },
            }}
          />
          <FormControlLabel
            key="Support"
            value="Support"
            control={
              <Checkbox
                checked={props.clas.indexOf("Support") > -1}
                disableRipple
                icon={<SquareIcon fontSize="small" />}
                checkedIcon={<SquareIcon fontSize="small" />}
                // onChange={() => {
                //   props.setSupport(!props.support);
                // }}
                name="Support"
                sx={{
                  transform: "scale(0.75)",
                  color: "#ffffff",
                  borderRadius: "0px",
                  "&.Mui-checked": {
                    color: "#a26f74",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                Support
              </Typography>
            }
            labelPlacement="start"
            sx={{
              ml: -0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "space-between",
              "& .Mui-checked": {
                color: "#a26f74",
              },
              width: "220px",
              "& .MuiCheckbox-colorPrimary.Mui-checked + .MuiTypography-root": {
                color: "#a26f74",
              },
            }}
          />
          <FormControlLabel
            key="Mounted"
            value="Mounted"
            control={
              <Checkbox
                checked={props.clas.indexOf("Mounted") > -1}
                disableRipple
                icon={<SquareIcon fontSize="small" />}
                checkedIcon={<SquareIcon fontSize="small" />}
                // onChange={() => {
                //   props.setMounted(!props.mounted);
                // }}
                name="Mounted"
                sx={{
                  transform: "scale(0.75)",
                  color: "#ffffff",
                  borderRadius: "0px",
                  "&.Mui-checked": {
                    color: "#a26f74",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                Mounted
              </Typography>
            }
            labelPlacement="start"
            sx={{
              ml: -0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "space-between",
              "& .Mui-checked": {
                color: "#a26f74",
              },
              width: "220px",
              "& .MuiCheckbox-colorPrimary.Mui-checked + .MuiTypography-root": {
                color: "#a26f74",
              },
            }}
          />
        </FormGroup>
      </FormControl>
    </Box>
  );
}
