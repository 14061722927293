import { Container, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import DiscordIcon from "../../assets/antara-exports/MainMenu/Discord.png";
import TwitterIcon from "../../assets/antara-exports/MainMenu/Twitter.png";
import TelegramIcon from "../../assets/antara-exports/MainMenu/Telegram.png";

let styles = {
  width: "40px",
  height: "auto",
};
let stylesTwitter = {
  width: "33px",
  height: "auto",
};

export default function NavButton(props: any) {
  var iconStyle = {
    opacity: 0.75,
  };
  console.log(props.currentPage.route, props.location.pathname);
  if (props.currentPage.route === props.location.pathname) {
    console.log("route matched");
    return (
      <Button
        href={props.currentPage.route}
        sx={{
          // fontWeight: "600px",
          "& .MuiTouchRipple-root": {
            color: "transparent",
            // justifyContent: "center",
          },
          "&:hover": {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
          fontSize: "1.4vw",
          my: 1,
          px: 2,
          pt: 0,
          pb: 1,
          color: "white",
          // display: "block",
          fontFamily: "Playfair Display SC",
          cursor: "pointer !important",
        }}
      >
        <Typography
          textAlign="center"
          sx={{
            p: 1,
            fontSize: "1.2vw",
            // fontWeight: "500px",
            "& .MuiTouchRipple-root": {
              color: "transparent",
            },
            ml: 3,
            fontFamily: "Playfair Display SC",
          }}
        >
          {props.currentPage.name}
        </Typography>
      </Button>
    );
  } else {
    console.log("route not matched");
    if (props.currentPage.name === "Whitepaper") {
      console.log("inside whitepaper button");

      return (
        <Button
          href={props.currentPage.route}
          target="_blank"
          sx={{
            fontFamily: "Playfair Display SC, serif",
            // fontSize: "16px",
            fontWeight: "500px",
            my: 1,
            color: "white",
            px: 1,
            "& .MuiTouchRipple-root": {
              color: "transparent",
              justifyContent: "center",
              alignItems: "center",
            },
            "&:hover": {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          <Typography
            sx={{
              p: 1,
              fontSize: "18px",
              fontWeight: "450px",
              "& .MuiTouchRipple-root": {
                color: "transparent",
              },
            }}
          >
            {props.currentPage.name}
          </Typography>
        </Button>
      );
    } else {
      return (
        <>
          <Button
            href={props.currentPage.route}
            sx={{
              // fontWeight: "600px",
              "& .MuiTouchRipple-root": {
                color: "transparent",
                // justifyContent: "center",
              },
              "&:hover": {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
              fontSize: "1.4vw",
              my: 1,
              px: 2,
              pt: 0,
              pb: 1,
              color: "white",
              // display: "block",
              fontFamily: "Playfair Display SC",
              cursor: "pointer !important",
            }}
          >
            {/* <Container maxWidth="xl"> */}
            <Typography
              textAlign="center"
              sx={{
                p: 1,
                fontSize: "1.2vw",
                // fontWeight: "500px",
                "& .MuiTouchRipple-root": {
                  color: "transparent",
                },
                ml: 3,
                fontFamily: "Playfair Display SC",
              }}
            >
              {props.currentPage.name === "Social" ? (
                <>
                  <ButtonBase
                    disableRipple
                    disableTouchRipple
                    sx={{
                      cursor: "pointer",
                      ml: -3,
                      mt: -0.5,
                      fontSize: "1.2vw",
                    }}
                    href={props.currentPage.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={TwitterIcon} style={stylesTwitter} />
                  </ButtonBase>
                  <ButtonBase
                    disableRipple
                    disableTouchRipple
                    sx={{
                      cursor: "pointer",
                      ml: 1,
                      mt: -0.5,
                      fontSize: "1.2vw",
                    }}
                    href={props.currentPage.discord}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={DiscordIcon} style={styles} />
                  </ButtonBase>

                  <ButtonBase
                    disableRipple
                    disableTouchRipple
                    sx={{
                      cursor: "pointer",
                      ml: 1,
                      mt: -0.5,
                      fontSize: "1.2vw",
                    }}
                    href={props.currentPage.telegram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={TelegramIcon} style={styles} />
                  </ButtonBase>
                </>
              ) : (
                props.currentPage.name
              )}
            </Typography>
            {/* </Container> */}
          </Button>
        </>
      );
    }
  }
}
