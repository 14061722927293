import React from "react";
import DopezInfo from "./DopezInfo";
import BlockzInfo from "./BlockzInfo";
import DozInfo from "./DozInfo";
import Contracts from "../../contractData/contracts";

export default function DynamicInfo(props: any) {
  return <DopezInfo cards={props.cards} />;
  if (props.type === "Character") {
    return <DopezInfo cards={props.cards} />;
  } else if (props.type?.toLowerCase() === Contracts.KINGDOM.toLowerCase()) {
    return <BlockzInfo cards={props.cards} />;
  } else if (props.type?.toLowerCase() === Contracts.CAMEL.toLowerCase()) {
    return <DozInfo cards={props.cards} />;
  } else {
    return <></>;
  }
}
