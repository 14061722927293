import React from "react";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import SquareIcon from "@mui/icons-material/Square";
import { Typography } from "@mui/material";

export default function Faction(props: any) {
  //const { "Swords Man", "Archer", "Fire Thrower", Javelin Thrower } = props.DopezClass;
  return (
    <Box sx={{ display: "flex" }}>
      <FormControl
        sx={{ my: 3, mt: 0, mb: 0 }}
        component="fieldset"
        variant="standard"
      >
        <FormGroup
          aria-label="position"
          row
          onChange={props.handleFactionChange}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <FormControlLabel
            key="Remus"
            value="Remus"
            control={
              <Checkbox
                checked={props.factions.indexOf("Remus") > -1}
                disableRipple
                icon={<SquareIcon fontSize="small" />}
                checkedIcon={<SquareIcon fontSize="small" />}
                name="Remus"
                sx={{
                  transform: "scale(0.75)",
                  color: "#ffffff",
                  borderRadius: "0px",
                  "&.Mui-checked": {
                    color: "#a26f74",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, medium, sans-serif",
                  "&.Mui-checked": {
                    color: "#a26f74",
                  },
                }}
              >
                Remus
              </Typography>
            }
            labelPlacement="start"
            sx={{
              ml: -0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "space-between",
              width: "220px",
              "& .Mui-checked": {
                color: "#a26f74",
              },
              "& .MuiCheckbox-colorPrimary.Mui-checked + .MuiTypography-root": {
                color: "#a26f74",
              },
            }}
          />
          <FormControlLabel
            key="Romulus"
            value="Romulus"
            control={
              <Checkbox
                checked={props.factions.indexOf("Romulus") > -1}
                disableRipple
                icon={<SquareIcon fontSize="small" />}
                checkedIcon={<SquareIcon fontSize="small" />}
                // onChange={() => {
                //   props.setRomulus(!props.romulus);
                // }}
                name="Romulus"
                sx={{
                  transform: "scale(0.75)",
                  color: "#ffffff",
                  borderRadius: "0px",
                  "&.Mui-checked": {
                    color: "#a26f74",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                Romulus
              </Typography>
            }
            labelPlacement="start"
            sx={{
              ml: -0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "space-between",
              "& .Mui-checked": {
                color: "#a26f74",
              },
              width: "220px",
              "& .MuiCheckbox-colorPrimary.Mui-checked + .MuiTypography-root": {
                color: "#a26f74",
              },
            }}
          />
          <FormControlLabel
            key="GreekEmpire"
            value="GreekEmpire"
            control={
              <Checkbox
                checked={props.factions.indexOf("GreekEmpire") > -1}
                disableRipple
                icon={<SquareIcon fontSize="small" />}
                checkedIcon={<SquareIcon fontSize="small" />}
                // onChange={() => {
                //   props.setGreekEmpire(!props.greekEmpire);
                // }}
                name="GreekEmpire"
                sx={{
                  transform: "scale(0.75)",
                  color: "#ffffff",
                  "&.Mui-checked": {
                    color: "#a26f74",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                Greek Empire
              </Typography>
            }
            labelPlacement="start"
            sx={{
              ml: -0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "space-between",
              "& .Mui-checked": {
                color: "#a26f74",
              },
              width: "220px",
              "& .MuiCheckbox-colorPrimary.Mui-checked + .MuiTypography-root": {
                color: "#a26f74",
              },
            }}
          />
          <FormControlLabel
            key="AfricanTribe"
            value="AfricanTribe"
            control={
              <Checkbox
                checked={props.factions.indexOf("AfricanTribe") > -1}
                disableRipple
                icon={<SquareIcon fontSize="small" />}
                checkedIcon={<SquareIcon fontSize="small" />}
                // onChange={() => {
                //   props.setAfricanTribes(!props.africanTribes);
                // }}
                name="AfricanTribes"
                sx={{
                  transform: "scale(0.75)",
                  color: "#ffffff",
                  borderRadius: "0px",
                  "&.Mui-checked": {
                    color: "#a26f74",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                African Tribes
              </Typography>
            }
            labelPlacement="start"
            sx={{
              //   color: "#a26f74",
              ml: -0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "space-between",
              "& .Mui-checked": {
                color: "#a26f74",
              },
              width: "220px",
              "& .MuiCheckbox-colorPrimary.Mui-checked + .MuiTypography-root": {
                color: "#a26f74",
              },
            }}
          />
          <FormControlLabel
            key="TBD"
            value="TBD"
            control={
              <Checkbox
                checked={props.factions.indexOf("TBD") > -1}
                disableRipple
                icon={<SquareIcon fontSize="small" />}
                checkedIcon={<SquareIcon fontSize="small" />}
                onChange={() => {
                  props.setTbd(!props.tbd);
                }}
                name="TBD"
                sx={{
                  transform: "scale(0.75)",
                  color: "#ffffff",
                  borderRadius: "0px",
                  "&.Mui-checked": {
                    color: "#a26f74",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat, medium, sans-serif",
                }}
              >
                Nordic Tribe
              </Typography>
            }
            labelPlacement="start"
            sx={{
              //   color: "#a26f74",
              ml: -0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "space-between",
              "& .Mui-checked": {
                color: "#a26f74",
              },
              width: "220px",
              "& .MuiCheckbox-colorPrimary.Mui-checked + .MuiTypography-root": {
                color: "#a26f74",
              },
            }}
          />
        </FormGroup>
      </FormControl>
    </Box>
  );
}
